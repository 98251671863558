/* ==============================================================
  Object - Filter Controls
================================================================= */
.filter-controls {
  display: flex;
  flex-direction: column;
}

.filter-controls--offset {
  overflow: auto;
  padding: 4rem 1.8rem;

  @include mq($from: xlarge) {
    padding: 6.125rem 7.1875rem;
  }
}

.filter-controls__nav {
  align-items: center;
  border-top: 1px solid $darkest;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
}

.filter-controls__actions {
  display: flex;
}

.filter-controls__actions > * {
  border-left: 1px solid $darkest;
  min-width: 112px;
}

.filter-controls__list {
  column-count: 4;
  column-gap: 20px;
  column-width: 200px;
  list-style: none;
  padding: 2.8rem 0 0 0;
  margin: 0;

  @include mq($from: medium) {
    margin-left: -#{$filterListOffset};
  }
}

.filter-controls__list > * {
  margin: .3rem 0;
}

.filter-controls__stack {
  list-style: none;
  margin: auto 0 0 0;
  padding: 0;
}


.filter-controls__stack > * + * {
  margin-top: .4rem;
}
