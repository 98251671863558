/* ==============================================================
  Object - Index Link
================================================================= */
.index-link {
  color: $darkest;
  display: block;
  font-family: $secondary;
  font-weight: 500;
  font-size: 1.5rem;
  height: 40px;
  line-height: 1.231;
  text-align: center;
  text-decoration: none;
  transition: color $BASE_TEMPO $EASE_PRIMARY;
  width: 40px;
}

.index-link > * {
  border-bottom: 1px solid transparent;
  pointer-events: none;
  vertical-align: middle;
  transition: border-color $BASE_TEMPO $EASE_PRIMARY;
}

.index-link.is-active,
.index-link:hover {
  color: $red;

  > * {
    border-color: $red;
  }
}
