/* ==============================================================
  Layout - Flyout
================================================================= */
.flyout {
  background-color: $lightest;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
  z-index: 20;
}

.flyout__content {
  padding: $wrapperOffset;

  @include mq($from: medium) {
    padding: 6.375rem;
  }
}

.flyout__header {
  align-items: center;
  background-color: $lightest;
  border-bottom: 1px solid $darkest;
  display: flex;
  left: 0;
  min-height: var(--masthead-height);
  padding-left: $wrapperOffset;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;

  @include mq($from: medium) {
    padding-left: 2.5rem;
  }
}

.flyout__header > * + * {
  border-left: 1px solid $darkest;
}

.flyout__header > *:first-child {
  flex-basis: 68%;
}

.flyout__header > *:last-child {
  flex-basis: 32%;
}

@include mq($from: medium) {
  .flyout {
    border-left: 1px solid $darkest;
    width: 603px;
  }
}

.flyout.is-present {
  transform: translateX(0);
}
