/* ==============================================================
  Object - Feature Hero
================================================================= */
.feature-hero {
  background-color: $darkest;
  display: grid;
  grid-template-areas: "hero";
  height: 80vh;
  max-height: 720px;
  overflow: hidden;
  place-items: center left;

  > * {
    grid-area: hero;
  }
}

.feature-hero--short {
  height: 70vh;
  max-height: 600px;
}

.feature-hero__content {
  justify-self: center;
  max-width: $constrainedWidth;
  width: 100%;
  z-index: 1;

  @include mq($until: $constrainedWidth) {
    padding-left: $wrapperOffset;
    padding-right: $wrapperOffset;
  }
}

.feature-hero__content-wrap {
  max-width: 293px;

  @include mq($from: small) {
    max-width: 888px;
  }
}

.feature-hero__media {
  height: 100%;
  max-height: 720px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  transform: translateY(var(--hero-transform));
  will-change: transform;
}

.feature-hero--short .feature-hero__media {
  max-height: 600px;
}
