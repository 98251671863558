/* ==============================================================
  Object - Austere Link
================================================================= */
.austere-link {
  @include type-austere();
  display: block;
  padding-left: 17px;
  position: relative;
  text-decoration: none;
}

.austere-link.is-active::before {
  content: "";
  background-color: $mustard;
  border-radius: 50%;
  display: block;
  height: 7px;
  left: 0;
  position: absolute;
  top: calc(50% - 2px);
  width: 7px;
}
