/* ==============================================================
  Layout - Grid Violator
================================================================= */
// width: 0.560416666666667
// height: 0.777777777777778
.grid-violator {
  max-height: 324px;
  height: 39.901477832vh;
  position: relative;

  @include mq($from: medium) {
    max-height: 700px;
    height: 78vh;
    width: 58vw;
  }

  @include mq($from: wrapper) {
    width: 56.041666666vw;
  }
}

.grid-violator__media {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
