/* ==============================================================
  Object - Crossfader
================================================================= */
.crossfader > * {
  height: 100vh;
  opacity: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 1000ms $EASE_PRIMARY;
  width: 100%;
}

.crossfader > *:first-child {
  opacity: 1;
}

.crossfader.is-active > *:first-child > .lazyloaded + .dyad__media__content--faded {
  opacity: 1;
}
