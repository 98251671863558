/* ==============================================================
  Object - Sneezeguard
================================================================= */
.sneezeguard {
  background-color: $darkest;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
  z-index: 10;
}

@include mq($from: medium) {
  .sneezeguard {
    z-index: 20;
  }
}

.sneezeguard.is-present {
  opacity: .3;
  pointer-events: all;
}
