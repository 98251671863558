/* ==============================================================
  Object - Block Navigation
================================================================= */
.block-navigation {

  @include mq($from: large) {
    align-items: center;
    display: flex;
  }
}

.block-navigation > *:first-child {

  @include mq($from: large) {
    margin-right: 5.3125rem;
  }
}

.block-navigation--inverted > *:first-child {

  @include mq($from: large) {
    margin-left: 5.3125rem;
    margin-right: 0;
  }
}

.block-navigation--inverted > *:last-child {
  order: -1;
}
