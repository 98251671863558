/* ==============================================================
  Layout - Grid Blocks
================================================================= */
.grid-blocks {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}

@include mq($from: small) {
  .grid-blocks--2up\@small {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include mq($from: medium) {
  .grid-blocks--4up\@medium {
    grid-template-columns: repeat(4, 1fr);
  }
}
