/* ==============================================================
  Object - Media Flourish
================================================================= */
.media-flourish {
  position: relative;
}

.media-flourish::before {
  content: "";
  display: block;
  background-image: url("../media/images/media-flourish.svg");
  background-position: left top;
  background-repeat: no-repeat;
  bottom: 0;
  height: 99px;
  left: -15px;
  position: absolute;
  transform: translateY(30%);
  width: 227px;
  z-index: 1;

  @include mq($from: medium) {
    height: 134px;
    width: 306px;
  }
}
