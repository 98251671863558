/* ==============================================================
  Layout - V Repeater
================================================================= */
.v-repeater {
  list-style: none;
  margin: 0;
  padding: 0;
}

.v-repeater > * + * {
  margin-top: 1.25rem;
}

.v-repeater--compressed > * + * {
  margin-top: .4rem;
}
