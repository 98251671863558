/* ==============================================================
  Object - Overlay
================================================================= */
.overlay {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding-bottom: 2rem;
  padding-left: $wrapperOffset;
  padding-right: $wrapperOffset;
  padding-top: 2rem;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
  z-index: 40;

  @include mq($from: medium) {
    padding-bottom: 5.0625rem;
    padding-top: 5.0625rem;
  }
}

.overlay--neutral {
  padding: 0;
}

.overlay.is-active {
  opacity: 1;
  pointer-events: all;
}

.overlay--light-mustard {
  background-color: $lightMustard;
}

.overlay--light-green {
  background-color: $lightGreen;
}
