/* ==============================================================
  Object - Nav List
================================================================= */
.nav {
  padding-bottom: 0;
  padding-top: 0;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__list {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.nav__list > * {
  position: relative;
}

.nav__list > * > ul {
  background-color: $lightest;
  border: 1px solid $darkest;
  left: -999em;
  min-width: 198px;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
}

.nav__list > *:hover > ul {
  left: 0;
  opacity: 1;
}

.nav__list > * > * > * + * {
  border-top: 1px solid $darkest;
}

.nav__list > * + * {
  margin-left: 34px;

  @include mq($from: xlarge) {
    margin-left: 54px;
  }
}
