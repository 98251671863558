/* ==============================================================
  Layout - H Repeater
================================================================= */
.h-repeater {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.h-repeater--baseline {
  align-items: baseline;
}

.h-repeater > * + * {
  margin-left: .8rem;

  @include mq($from: medium) {
    margin-left: 1.25rem;
  }
}

.h-repeater--expanded > * + * {
  margin-left: 2.5rem;
}
