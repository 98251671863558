/* ==============================================================
  Object - Filter Dispatcher
================================================================= */
.filter-dispatcher {
  border-bottom: 1px solid $green;
  color: $darkest;
  display: inline-block;
  font-family: $secondary;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -.005555555em;
  padding-bottom: 1px;
  position: relative;
  text-decoration: none;

  @include mq($from: medium) {
    font-size: 2.25rem;
  }
}

.filter-dispatcher--dark {
  border-bottom-color: $mustard;
}

.filter-dispatcher::before {
  content: "";
  display: block;
  background-color: $lightest;
  border-radius: 50%;
  height: 9px;
  left: -23px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 1000ms $EASE_PRIMARY;
  width: 9px;
}

.filter-dispatcher:hover::before,
.filter-dispatcher.is-active::before {
  opacity: 1;
}
