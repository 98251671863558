@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/OggText-Book.woff2"),
    url("../fonts/OggText-Book.woff");
}

@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: italic;
  font-weight: normal;
  src:
    url("../fonts/OggText-BookItalic.woff2"),
    url("../fonts/OggText-BookItalic.woff");
}

@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: normal;
  font-weight: 700;
  src:
    url("../fonts/OggText-Bold.woff2"),
    url("../fonts/OggText-Bold.woff");
}

@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: italic;
  font-weight: 700;
  src:
    url("../fonts/OggText-BoldItalic.woff2"),
    url("../fonts/OggText-BoldItalic.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: normal;
  src:
    url("../fonts/founders-grotesk-web-regular.woff2"),
    url("../fonts/founders-grotesk-web-regular.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 400;
  src:
    url("../fonts/founders-grotesk-web-regular.woff2"),
    url("../fonts/founders-grotesk-web-regular.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: italic;
  font-weight: 400;
  src:
    url("../fonts/founders-grotesk-web-regular-italic.woff2"),
    url("../fonts/founders-grotesk-web-regular-italic.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 500;
  src:
    url("../fonts/founders-grotesk-web-medium.woff2"),
    url("../fonts/founders-grotesk-web-medium.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: italic;
  font-weight: 500;
  src:
    url("../fonts/founders-grotesk-web-medium-italic.woff2"),
    url("../fonts/founders-grotesk-web-medium-italic.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 600;
  src:
    url("../fonts/founders-grotesk-web-semibold.woff2"),
    url("../fonts/founders-grotesk-web-semibold.woff");
}

@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: italic;
  font-weight: 600;
  src:
    url("../fonts/founders-grotesk-web-semibold-italic.woff2"),
    url("../fonts/founders-grotesk-web-semibold-italic.woff");
}
