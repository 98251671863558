/* ==============================================================
  Object - Header Block
================================================================= */
.header-block {
  border-bottom: 1px solid $darkest;
  padding-bottom: 1.8rem;
}

.header-block > *:last-of-type {
  margin-bottom: 0;
}
