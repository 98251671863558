@mixin type-action($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: .01875em;
  text-decoration: none;

  @include mq($from: medium) {
    font-size: 1.125rem;
  }
}

@mixin type-austere($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -.007142857em;
  line-height: 1.231;
}

@mixin type-body($color: $darkest) {
  color: $color;
  font-family: $primary;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
}

@mixin type-eyebrow($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-weight: 400;
  font-size: .875rem;
  letter-spacing: 1.3px;
  line-height: 1.428571428;
  text-transform: uppercase;

  > a {
    border-bottom: 1px solid currentColor;
    color: $red;
    display: inline-block;
    line-height: 1.231;
    text-decoration: none;
  }
}

@mixin type-h1($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: -.004166666em;
  line-height: 1;

  @include mq($from: medium) {
    font-size: 3rem;
  }
}

@mixin type-h2($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -.005555555em;
  line-height: 1;

  > a {
    color: $darkest;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include mq($from: medium) {
    font-size: 2.25rem;
  }
}

@mixin type-h3($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.125;
  text-decoration: none;

  @include mq($from: medium) {
    font-size: 1.5rem;
  }
}

@mixin type-highlight($color: $red) {
  color: $color;
  font-family: $secondary;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .01875em;
  line-height: 1.25;
  text-decoration: none;
}

@mixin type-label($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: .01875em;
  line-height: 1.25;
}

@mixin type-meta($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: .01875em;
  line-height: 1.25;

  a {
    color: $color;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  strong {
    font-weight: 500;
  }
}

@mixin type-minimal($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: .01875em;
}

@mixin type-minimal-austere($color: $darkest) {
  color: $color;
  font-family: $secondary;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: .01875em;
}

@mixin type-minimal-control($color: darkest) {
  color: $color;
  font-family: $secondary;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: .01875em;
  text-decoration: none;
}

.type-h3 {
  @include type-h3();

  > a {
    color: currentColor;
    text-decoration: none;
  }
}

.type-h3--inverted {
  @include type-h3($lightest);

  > a {
    color: $lightest;
    text-decoration: none;
  }
}

.type-h3--underline {
  border-bottom: 1px solid $darkest;
  display: inline-block;
  padding-bottom: .11em;
}

.type-h3--loader {
  opacity: 0;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
}

.type-action-link {
  @include type-highlight($mustard);
  border-bottom: 1px solid $lightMustard;
  display: inline-block;
  padding-bottom: .11em;
}

.type-action {
  @include type-action();

  > a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

a.type-action {
  transition: color $BASE_TEMPO $EASE_PRIMARY;
}

a.type-action:hover,
a.type-action.is-active {
  color: $mustard;
}

.type-action--inverted {
  @include type-action($lightest);
}

.type-austere {
  @include type-austere();
}

a.type-austere {
  text-decoration: none;
}

.type-body {
  @include type-body();
}

.type-eyebrow {
  @include type-eyebrow();
}

.type-eyebrow--inverted {
  @include type-eyebrow($lightest);
}

.type-h1 {
  @include type-h1();
}

.type-h1--inverted {
  @include type-h1($lightest);
}

.type-h2 {
  @include type-h2();
}

.type-h2--inverted {
  @include type-h2($lightest);
}

.type-highlight {
  @include type-highlight();
}

a.type-highlight:hover {
  text-decoration: underline;
}

.type-label {
  @include type-label();
}

.type-meta {
  @include type-meta();
}

.type-meta--inverted {
  @include type-meta($lightest);
}

.type-minimal {
  @include type-minimal();
}

.type-minimal--highlight {
  @include type-minimal($lightRed);
}

.type-minimal-austere {
  @include type-minimal-austere();
}

.type-minimal-control {
  @include type-minimal-control();
}

.type-minimal--inverted {
  @include type-minimal($lightest);
}

.type-minimal-control--inverted {
  @include type-minimal-control($lightest);
}
