/* ==============================================================
  Object - Mini Controls
================================================================= */
.mini-controls {
  align-items: center;
  display: flex;
}

.mini-controls__action {
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: color $BASE_TEMPO $EASE_PRIMARY;
  user-select: none;
  height: 40px;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
}

.mini-controls__icon > svg {
  margin: 0 auto;
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
  width: 17px;

  > path {
    stroke: $red;
  }
}

.mini-controls__action:first-child:hover .mini-controls__icon > svg {
  transform: translateX(-5px);
}

.mini-controls__action:last-child:hover .mini-controls__icon > svg {
  transform: translateX(5px);
}

