/* ==============================================================
  Object - Dyad
================================================================= */
.dyad {
  display: flex;
  flex-direction: column;

  @include mq($from: medium) {
    flex-direction: row;
  }
}

.dyad > * {
  position: relative;

  @include mq($from: medium) {
    height: 100vh;
    width: 50%;
  }
}

.dyad--short > * {
  @include mq($from: medium) {
    height: 89vh;
    max-height: 800px;
  }
}

.dyad--asymmetrical > * {

  @include mq($from: medium) {
    height: 78vh;
    max-height: 700px;
  }
}

.dyad__media {
  display: none;

  @include mq($from: medium) {
    display: grid;
    grid-template-areas: "dyadMedia";
    height: 100vh;
    overflow: hidden;
    place-items: center;

    > * {
      grid-area: dyadMedia;
    }
  }
}

.dyad__media--persist {
  display: grid;
  grid-template-areas: "dyadMedia";
  height: 324px;
  overflow: hidden;
  place-items: center;

  > * {
    grid-area: dyadMedia;
  }

  @include mq($from: medium) {
    height: 100vh;
  }
}

.dyad--asymmetrical > *:first-child {
  @include mq($from: medium) {
    width: 43.958333334%;
  }
}

.dyad--asymmetrical > *:last-child {
  @include mq($from: medium) {
    width: 56.041666666%;
  }
}

.dyad__media--flourish {
  overflow: visible;
}

.dyad__media--flourish::before {
  content: "";
  bottom: 0;
  display: block;
  background-image: url("../media/images/media-flourish.svg");
  background-repeat: no-repeat;
  left: 50%;
  position: absolute;
  height: 127px;
  width: 333px;
  transform: translate(-50%, 50%);
  z-index: 1;
}

.dyad--short .dyad__media {
  height: 89vh;
  max-height: 800px;
}

.dyad__media__media {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.dyad--short .dyad__media__media {
  max-height: 800px;
}

.dyad__media__content--faded {
  opacity: 0;
  transition: opacity 800ms linear 550ms;
}

.fader-is-active .dyad__media__content--faded {
  opacity: 1;
}

