/* ==============================================================
  Object - Column Synopsis
================================================================= */
.column-synopsis {
  display: flex;
  flex-direction: column;
  min-height: 436px;
  height: 100%;

  @include mq($from: medium) {
    min-height: auto;
  }
}

.column-synopsis__meta {
  border-top: 1px solid $darkest;
  padding-top: 1.25rem;
}

.column-synopsis__meta + * {
  margin-top: auto;
}

.column-synopsis p + p {
  margin-top: 1.5rem;
}

.column-synopsis__content a {
  color: $darkest;
}

.column-synopsis__content a:hover {
  text-decoration: none;
}
