/* ==============================================================
  Layout - Lazy Media
================================================================= */
.lazy-media {
  position: relative;
}

.lazy-media__media {
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    width: 100%;
  }
}

.lazy-media__media--flourished > * {
  position: relative;
}

.lazy-media__media--flourished > *::before {
  content: "";
  background-image: url("../media/images/flourish-5.svg");
  background-position: left top;
  background-repeat: no-repeat;
  bottom: 8%;
  display: block;
  height: 90px;
  right: 0;
  position: absolute;
  transform: translate(15px, 50%);
  width: 100px;
  z-index: 1;

  @include mq($from: xlarge) {
    height: 161px;
    left: 87.7%;
    right: auto;
    transform: translate(0, 0);
    width: 178px;
  }
}

.lazy-media__media + figcaption {
  margin-top: .375rem;
  position: relative;
  z-index: 1;
}
