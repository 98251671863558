/* ==============================================================
  Object - Footer
================================================================= */
.footer {
  background-color: $darkest;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 498px;
  padding-bottom: $wrapperOffset;
  padding-left: $wrapperOffset;
  padding-top: 3.75rem;
  padding-right: $wrapperOffset;

  @include mq($from: medium) {
    padding-top: 7.125rem;
  }
}
