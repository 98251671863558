/* ==============================================================
  Object - Branding
================================================================= */
.button {
  appearance: none;
  background-color: transparent;
  border: 1px solid $darkest;
  color: inherit;
  display: inline-block;
  font-family: $secondary;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  padding: .58em 1.111111111em;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color $BASE_TEMPO $EASE_PRIMARY;
  user-select: none;

  &:hover {
    // background-color: $darkest;
    color: $lightest;
    cursor: pointer;
  }
}

.button__text {
  position: relative;
  z-index: 1;
}

.button:not(.button--stealth)::after {
  content: "";
  background-color: $darkest;
  display: block;
  height: 100%;
  right: 100%;
  position: absolute;
  top: 0;
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
}

.button:hover::after {
  transform: translateX(100%);
}

.button--spectral {
  background-color: transparent;
  border-color: $lightest;
  color: $lightest;

  &:hover {
    color: $darkest;
  }

  &:not(.button--stealth)::after {
    background-color: $lightest;
  }
}

.button--stealth {
  background-color: transparent;
  border: none;
  font-size: initial;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.button--with-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.button--with-icon__icon {
  margin-left: .5em;
  position: relative;
  width: 12px;

  > svg path,
  > svg circle,
  > svg line {
    stroke: currentColor;
  }
}
