::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $darkest;
  opacity: .3;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $darkest;
  opacity: .3;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $darkest;
  opacity: .3;
}

:-moz-placeholder { /* Firefox 18- */
  color: $darkest;
  opacity: .3;
}

:root {
  --grid-gutter: 2.083333333vw;
  --masthead-height: 108px;
  --wrapper-offset: 30px;
}

@include mq($from: medium) {
  :root {
    --masthead-height: 80px;
  }
}

/* https://github.com/jensimmons/cssremedy/blob/master/css/reminders.css#L31 */
/* Reduced Motion
*****************/

/*
* 1. Immediately jump any animation to the end point
* 2. Remove transitions & fixed background attachment
* See: https://github.com/mozdevs/cssremedy/issues/11
*/
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}

:focus {
  outline: thin dotted;
}

html,
body {
  background-color: $lightest;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: $darkest;
  @include type-body();
  font-feature-settings: "liga", "clig";
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: inherit;
}

button,
input[type="email"],
input[type="text"],
textarea {
  appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

p {
  margin: 0;
}

p:empty {
  margin-bottom: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

figure {
  display: block;
  margin: 0;
}

label {
  display: block;
}

.hr {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  height: 1px;
  border: none;
  background: $darkest;
  color: $darkest;
  line-height: 1px;
  font-size: 1px;
}
