/* ==============================================================
  Object - Dynamic Bars
================================================================= */
.dynamic-bars {
  display: inline-flex;
  height: 14px;
  position: relative;
  width: 22px;
}

.dynamic-bars__elements {
  background-color: $darkest;
  height: 1px;
  left: 0;
  position: absolute;
  top: 7px;
  transition: transform .1s $EASE_PRIMARY;
  width: 22px;
}

button[aria-expanded="true"] .dynamic-bars__elements {
  transform: rotate(45deg);
  transition: transform .1s $EASE_PRIMARY .14s;
}

.dynamic-bars__elements::before,
.dynamic-bars__elements::after {
  content: "";
  background-color: $darkest;
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 22px;
}

.dynamic-bars__elements::before {
  top: -7px;
  transition:
    transform .1s $EASE_PRIMARY .14s,
    opacity .1s $EASE_PRIMARY;
}

.dynamic-bars__elements::after {
  bottom: -7px;
  transition:
    bottom .1s $EASE_PRIMARY .14s,
    transform .1s $EASE_PRIMARY;
}

button[aria-expanded="true"] .dynamic-bars__elements::before {
  opacity: 0;
  transform: translateY(7px);
  transition:
    transform .1s $EASE_PRIMARY,
    opacity .1s $EASE_PRIMARY;
}

button[aria-expanded="true"] .dynamic-bars__elements::after {
  bottom: 0;
  transition:
    bottom .1s $EASE_PRIMARY,
    transform .1s $EASE_PRIMARY .14s;
  transform: rotate(-90deg);
}
