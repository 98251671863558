.u-scroll-lock {
  overflow: hidden;
}

.u-scroll-target {
  scroll-margin-top: calc(var(--masthead-height) + .3rem);
}

.u-scroll-target-flush {
  scroll-margin-top: var(--masthead-height);
}
