/* stylelint-disable */
/* ==============================================================
  Object - User Content
================================================================= */
.user-content {
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  @include type-body();

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: .8em;
    margin-top: 1.5em;
  }

  h1 {
    @include type-h1();
  }

  h2 {
    @include type-h2();
  }

  h3 {
    @include type-h3();
  }

  figure {
    display: block;
    margin: 0;
  }

  p {
    margin-bottom: 1.5rem;
  }

  a {
    color: $red;
    padding-bottom: 0;
  }

  blockquote {
    box-sizing: border-box;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1.2rem;
    border-left: 5px solid;
    padding: 1rem 1.2rem;
    font-family: inherit;
    font-size: 1.1rem;
    font-style: italic;
  }

  blockquote > *:last-child {
    margin-bottom: 0; /* 3 */
  }

  figcaption {
    font-size: .9rem;
    font-style: italic;
    margin-top: .3rem;
  }

  ul,
  ol {
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0;
  }

  ol ul,
  ul ul {
    list-style-type: circle;
  }

  ol ol ul,
  ol ul ul,
  ul ol ul,
  ul ul ul {
    list-style-type: square;
  }

  ul > li + li,
  ol > li + li {
    margin-top: 1rem;
  }

  ul > li > ul > li + li,
  ol > li > ol > li + li {
    margin-top: .3rem;
  }

  ul > li > ul,
  ol > li > ol {
    margin-top: .5rem;
  }

  table {
    width: 100% !important;
    margin-bottom: 1.7rem;
    border-spacing: 0;
    border-collapse: collapse;
  }

  caption {
    font-size: smaller;
    padding: 10px 12px;
    text-align: center;
  }

  tr {
    vertical-align: middle;
  }

  tbody {
    vertical-align: middle;
  }

  thead {
    vertical-align: middle;
  }

  tfoot {
    vertical-align: middle;
  }

  td {
    vertical-align: inherit;
    text-align: left;
    padding: 4px 12px;
    border: 1px solid $blue;
  }

  th {
    vertical-align: inherit;
    text-align: left;
    font-weight: bold;
    padding: 8px 12px;
    border: 1px solid $blue;
    background-color: transparent;
  }
}

.user-content--constrained {
  max-width: 553px;
}

.section--longFormText .content-constrain > .section + .section {
  margin-top: 2.5rem;
}

.user-content + .user-content__media,
.user-content__media + .user-content__media,
.user-content__media + .user-content,
.user-content + .user-content,
.user-content + .callout {
  margin-top: 2.5rem;
}

.user-content__media > figure {
  position: relative;
}

.user-content__media > figure > img {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.user-content--mini {
  h4 {
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin-bottom: 1.5rem;
  }
}
