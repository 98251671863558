/* ==============================================================
  Object - Pagination
================================================================= */
.pagination {
  display: flex;
  justify-content: center;
}

.pagination > * {
  display: flex;
  padding-left: 56px;
  padding-right: 56px;
  position: relative;
}

.pagination__control {
  left: 0;
  position: absolute;
  top: 0;
}

.pagination__control:last-child {
  left: auto;
  right: 0;
}

.pagination__action {
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: color $BASE_TEMPO $EASE_PRIMARY;
  user-select: none;
  height: 40px;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
}

.pagination__action > * {
  pointer-events: none;
}

.pagination__icon > svg {
  margin: 0 auto;
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
  width: 17px;

  > path {
    stroke: $red;
  }
}

.pagination__control:first-child .pagination__action:hover .pagination__icon > svg {
  transform: translateX(-5px);
}

.pagination__control:last-child .pagination__action:hover .pagination__icon > svg {
  transform: translateX(5px);
}
