@import "normalize.css";
@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/OggText-Book.woff2"), url("../fonts/OggText-Book.woff");
}
@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/OggText-BookItalic.woff2"), url("../fonts/OggText-BookItalic.woff");
}
@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/OggText-Bold.woff2"), url("../fonts/OggText-Bold.woff");
}
@font-face {
  font-display: swap;
  font-family: Ogg;
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/OggText-BoldItalic.woff2"), url("../fonts/OggText-BoldItalic.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/founders-grotesk-web-regular.woff2"), url("../fonts/founders-grotesk-web-regular.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/founders-grotesk-web-regular.woff2"), url("../fonts/founders-grotesk-web-regular.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/founders-grotesk-web-regular-italic.woff2"), url("../fonts/founders-grotesk-web-regular-italic.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/founders-grotesk-web-medium.woff2"), url("../fonts/founders-grotesk-web-medium.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/founders-grotesk-web-medium-italic.woff2"), url("../fonts/founders-grotesk-web-medium-italic.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/founders-grotesk-web-semibold.woff2"), url("../fonts/founders-grotesk-web-semibold.woff");
}
@font-face {
  font-display: swap;
  font-family: FoundersGrotesk;
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/founders-grotesk-web-semibold-italic.woff2"), url("../fonts/founders-grotesk-web-semibold-italic.woff");
}
.type-h3 {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.125;
  text-decoration: none;
}
@media (min-width: 48em) {
  .type-h3 {
    font-size: 1.5rem;
  }
}
.type-h3 > a {
  color: currentColor;
  text-decoration: none;
}

.type-h3--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.125;
  text-decoration: none;
}
@media (min-width: 48em) {
  .type-h3--inverted {
    font-size: 1.5rem;
  }
}
.type-h3--inverted > a {
  color: #ffffff;
  text-decoration: none;
}

.type-h3--underline {
  border-bottom: 1px solid #000000;
  display: inline-block;
  padding-bottom: 0.11em;
}

.type-h3--loader {
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.type-action-link {
  color: #986e12;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01875em;
  line-height: 1.25;
  text-decoration: none;
  border-bottom: 1px solid #cc9c43;
  display: inline-block;
  padding-bottom: 0.11em;
}

.type-action {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.01875em;
  text-decoration: none;
}
@media (min-width: 48em) {
  .type-action {
    font-size: 1.125rem;
  }
}
.type-action > a {
  color: currentColor;
  text-decoration: none;
}
.type-action > a:hover {
  text-decoration: underline;
}

a.type-action {
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

a.type-action:hover,
a.type-action.is-active {
  color: #986e12;
}

.type-action--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.01875em;
  text-decoration: none;
}
@media (min-width: 48em) {
  .type-action--inverted {
    font-size: 1.125rem;
  }
}

.type-austere {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.007142857em;
  line-height: 1.231;
}

a.type-austere {
  text-decoration: none;
}

.type-body {
  color: #000000;
  font-family: Ogg, Georgia, Times, "Times New Roman", serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
}

.type-eyebrow {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 1.3px;
  line-height: 1.428571428;
  text-transform: uppercase;
}
.type-eyebrow > a {
  border-bottom: 1px solid currentColor;
  color: #cc4e13;
  display: inline-block;
  line-height: 1.231;
  text-decoration: none;
}

.type-eyebrow--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 1.3px;
  line-height: 1.428571428;
  text-transform: uppercase;
}
.type-eyebrow--inverted > a {
  border-bottom: 1px solid currentColor;
  color: #cc4e13;
  display: inline-block;
  line-height: 1.231;
  text-decoration: none;
}

.type-h1 {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: -0.004166666em;
  line-height: 1;
}
@media (min-width: 48em) {
  .type-h1 {
    font-size: 3rem;
  }
}

.type-h1--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: -0.004166666em;
  line-height: 1;
}
@media (min-width: 48em) {
  .type-h1--inverted {
    font-size: 3rem;
  }
}

.type-h2 {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -0.005555555em;
  line-height: 1;
}
.type-h2 > a {
  color: #000000;
  text-decoration: none;
}
.type-h2 > a:hover {
  text-decoration: underline;
}
@media (min-width: 48em) {
  .type-h2 {
    font-size: 2.25rem;
  }
}

.type-h2--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -0.005555555em;
  line-height: 1;
}
.type-h2--inverted > a {
  color: #000000;
  text-decoration: none;
}
.type-h2--inverted > a:hover {
  text-decoration: underline;
}
@media (min-width: 48em) {
  .type-h2--inverted {
    font-size: 2.25rem;
  }
}

.type-highlight {
  color: #cc4e13;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01875em;
  line-height: 1.25;
  text-decoration: none;
}

a.type-highlight:hover {
  text-decoration: underline;
}

.type-label {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.01875em;
  line-height: 1.25;
}

.type-meta {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.01875em;
  line-height: 1.25;
}
.type-meta a {
  color: #000000;
  text-decoration: none;
}
.type-meta a:hover {
  text-decoration: underline;
}
.type-meta strong {
  font-weight: 500;
}

.type-meta--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.01875em;
  line-height: 1.25;
}
.type-meta--inverted a {
  color: #ffffff;
  text-decoration: none;
}
.type-meta--inverted a:hover {
  text-decoration: underline;
}
.type-meta--inverted strong {
  font-weight: 500;
}

.type-minimal {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01875em;
}

.type-minimal--highlight {
  color: #ff7342;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01875em;
}

.type-minimal-austere {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.01875em;
}

.type-minimal-control {
  color: darkest;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01875em;
  text-decoration: none;
}

.type-minimal--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01875em;
}

.type-minimal-control--inverted {
  color: #ffffff;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01875em;
  text-decoration: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000;
  opacity: 0.3;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
  opacity: 0.3;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
  opacity: 0.3;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
  opacity: 0.3;
}

:root {
  --grid-gutter: 2.083333333vw;
  --masthead-height: 108px;
  --wrapper-offset: 30px;
}

@media (min-width: 48em) {
  :root {
    --masthead-height: 80px;
  }
}
/* https://github.com/jensimmons/cssremedy/blob/master/css/reminders.css#L31 */
/* Reduced Motion
*****************/
/*
* 1. Immediately jump any animation to the end point
* 2. Remove transitions & fixed background attachment
* See: https://github.com/mozdevs/cssremedy/issues/11
*/
@media (prefers-reduced-motion: reduce) {
  *,
::before,
::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}
:focus {
  outline: thin dotted;
}

html,
body {
  background-color: #ffffff;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  color: #000000;
  font-family: Ogg, Georgia, Times, "Times New Roman", serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
  font-feature-settings: "liga", "clig";
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: inherit;
}

button,
input[type=email],
input[type=text],
textarea {
  appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

p {
  margin: 0;
}

p:empty {
  margin-bottom: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

figure {
  display: block;
  margin: 0;
}

label {
  display: block;
}

.hr {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  height: 1px;
  border: none;
  background: #000000;
  color: #000000;
  line-height: 1px;
  font-size: 1px;
}

/* ==============================================================
  Layout - Carousel
================================================================= */
.carousel {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 95vw;
}
@media (min-width: 48em) {
  .carousel {
    width: 70.208333333vw;
  }
}

.carousel.flickity-enabled {
  display: block;
}

.carousel__slide {
  opacity: 0.5;
  margin-right: 28px;
  min-width: 220px;
  transition: opacity linear 0.5s;
  width: 27.299703264%;
}

.carousel__slide.is-selected {
  opacity: 1;
}

/* ==============================================================
  Layout - Content Constrain
================================================================= */
.content-constrain {
  margin: 0 auto;
  max-width: 785px;
}

/* ==============================================================
  Layout - Control Group
================================================================= */
.control-group {
  display: flex;
}

.control-group > * + * {
  margin-left: 12px;
}

.control-group > *:first-child {
  flex: 1;
}

/* ==============================================================
  Layout - Decorative Repeater
================================================================= */
.decorative-repeater {
  list-style: none;
  margin: 0;
  padding: 0;
}

.decorative-repeater > * + * {
  border-top: 1px solid #000000;
  margin-top: 3.375rem;
  padding-top: 1.875rem;
}

.decorative-repeater--inverted > * {
  border-bottom: 1px solid #999999;
  padding-bottom: 2rem;
}

.decorative-repeater--inverted > * + * {
  border-top: none;
  margin-top: 2rem;
  padding-top: 0;
}

/* ==============================================================
  Layout - Flyout
================================================================= */
.flyout {
  background-color: #ffffff;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  z-index: 20;
}

.flyout__content {
  padding: 1.125rem;
}
@media (min-width: 48em) {
  .flyout__content {
    padding: 6.375rem;
  }
}

.flyout__header {
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #000000;
  display: flex;
  left: 0;
  min-height: var(--masthead-height);
  padding-left: 1.125rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
}
@media (min-width: 48em) {
  .flyout__header {
    padding-left: 2.5rem;
  }
}

.flyout__header > * + * {
  border-left: 1px solid #000000;
}

.flyout__header > *:first-child {
  flex-basis: 68%;
}

.flyout__header > *:last-child {
  flex-basis: 32%;
}

@media (min-width: 48em) {
  .flyout {
    border-left: 1px solid #000000;
    width: 603px;
  }
}
.flyout.is-present {
  transform: translateX(0);
}

/* ==============================================================
  Layout - Grid
================================================================= */
.grid {
  display: grid;
  row-gap: 3.125rem;
}
@media (min-width: 48em) {
  .grid {
    column-gap: 30px;
    row-gap: unset;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.grid--sticky {
  align-items: baseline;
}

.grid--v-align {
  align-items: center;
}

.grid__col--insulated {
  padding-bottom: 0;
  padding-top: 1.875rem;
}
@media (min-width: 48em) {
  .grid__col--insulated {
    padding-bottom: 5.3125rem;
    padding-top: 3.75rem;
  }
}
@media (max-width: 74.615em) {
  .grid__col--insulated {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}

@media (min-width: 48em) {
  .grid__col--offset-minor {
    margin-top: 4.0625rem;
  }

  .grid__col {
    grid-row-start: 1;
  }

  .grid__col--start-1-span-2\@medium {
    grid-column: 1/span 2;
  }

  .grid__col--start-1-span-3\@medium {
    grid-column: 1/span 3;
  }

  .grid__col--start-1-span-4\@medium {
    grid-column: 1/span 4;
  }

  .grid__col--start-1-span-5\@medium {
    grid-column: 1/span 5;
  }

  .grid__col--start-1-span-6\@medium {
    grid-column: 1/span 6;
  }

  .grid__col--start-3-span-3\@medium {
    grid-column: 3/span 3;
  }

  .grid__col--start-4-span-3\@medium {
    grid-column: 4/span 3;
  }

  .grid__col--start-4-span-6\@medium {
    grid-column: 4/span 6;
  }

  .grid__col--start-4-span-9\@medium {
    grid-column: 4/span 9;
  }

  .grid__col--start-6-span-5\@medium {
    grid-column: 6/span 5;
  }

  .grid__col--start-6-span-7\@medium {
    grid-column: 6/span 7;
  }

  .grid__col--start-7-span-3\@medium {
    grid-column: 7/span 3;
  }

  .grid__col--start-7-span-5\@medium {
    grid-column: 7/span 5;
  }

  .grid__col--start-7-span-6\@medium {
    grid-column: 7/span 6;
  }

  .grid__col--start-10-span-3\@medium {
    grid-column: 10/span 3;
  }

  .grid__col--start-11-span-2\@medium {
    grid-column: 11/span 2;
  }

  .grid__col--image-offset {
    padding-top: 28px;
  }

  .grid__col--sticky {
    position: sticky;
    top: calc(var(--masthead-height) + 2rem);
  }
}
@media (max-width: 47.99em) {
  .grid__col--invert\@medium {
    order: -1;
  }
}
/* ==============================================================
  Layout - Grid Blocks
================================================================= */
.grid-blocks {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}

@media (min-width: 31.25em) {
  .grid-blocks--2up\@small {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 48em) {
  .grid-blocks--4up\@medium {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* ==============================================================
  Layout - Grid Violator
================================================================= */
.grid-violator {
  max-height: 324px;
  height: 39.901477832vh;
  position: relative;
}
@media (min-width: 48em) {
  .grid-violator {
    max-height: 700px;
    height: 78vh;
    width: 58vw;
  }
}
@media (min-width: 90em) {
  .grid-violator {
    width: 56.041666666vw;
  }
}

.grid-violator__media {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

/* ==============================================================
  Layout - H Repeater
================================================================= */
.h-repeater {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.h-repeater--baseline {
  align-items: baseline;
}

.h-repeater > * + * {
  margin-left: 0.8rem;
}
@media (min-width: 48em) {
  .h-repeater > * + * {
    margin-left: 1.25rem;
  }
}

.h-repeater--expanded > * + * {
  margin-left: 2.5rem;
}

/* ==============================================================
  Layout - Lazy Media
================================================================= */
.lazy-media {
  position: relative;
}

.lazy-media__media {
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.lazy-media__media img {
  width: 100%;
}

.lazy-media__media--flourished > * {
  position: relative;
}

.lazy-media__media--flourished > *::before {
  content: "";
  background-image: url("../media/images/flourish-5.svg");
  background-position: left top;
  background-repeat: no-repeat;
  bottom: 8%;
  display: block;
  height: 90px;
  right: 0;
  position: absolute;
  transform: translate(15px, 50%);
  width: 100px;
  z-index: 1;
}
@media (min-width: 80em) {
  .lazy-media__media--flourished > *::before {
    height: 161px;
    left: 87.7%;
    right: auto;
    transform: translate(0, 0);
    width: 178px;
  }
}

.lazy-media__media + figcaption {
  margin-top: 0.375rem;
  position: relative;
  z-index: 1;
}

/* ==============================================================
  Layout - VR
================================================================= */
.vr {
  margin-bottom: 0.25rem;
}

.vr--2x {
  margin-bottom: 0.5rem;
}

.vr--3x {
  margin-bottom: 0.75rem;
}

.vr--4x {
  margin-bottom: 1rem;
}

.vr--5x {
  margin-bottom: 1.25rem;
}

.vr--6x {
  margin-bottom: 1.5rem;
}

.vr--7x {
  margin-bottom: 1.75rem;
}

.vr--8x {
  margin-bottom: 2rem;
}

.vr--9x {
  margin-bottom: 2.25rem;
}

.vr--10x {
  margin-bottom: 2.5rem;
}

.vr--11x {
  margin-bottom: 2.75rem;
}

.vr--12x {
  margin-bottom: 3rem;
}

.vr--13x {
  margin-bottom: 3.25rem;
}

.vr--14x {
  margin-bottom: 3.5rem;
}

.vr--15x {
  margin-bottom: 3.75rem;
}

.vr--16x {
  margin-bottom: 4rem;
}

.vr--17x {
  margin-bottom: 4.25rem;
}

.vr--18x {
  margin-bottom: 4.5rem;
}

.vr--19x {
  margin-bottom: 4.75rem;
}

.vr--29x {
  margin-bottom: 7.25rem;
}

.vr--30x {
  margin-bottom: 7.5rem;
}

.vr--31x {
  margin-bottom: 7.75rem;
}

.vr--32x {
  margin-bottom: 8rem;
}

@media (min-width: 48em) {
  .vr--cancel\@medium {
    margin-bottom: 0;
  }

  .vr--30x\@medium {
    margin-bottom: 7.5rem;
  }
}
/* ==============================================================
  Layout - V Repeater
================================================================= */
.v-repeater {
  list-style: none;
  margin: 0;
  padding: 0;
}

.v-repeater > * + * {
  margin-top: 1.25rem;
}

.v-repeater--compressed > * + * {
  margin-top: 0.4rem;
}

/* ==============================================================
  Layout - Splitter
================================================================= */
.splitter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.splitter--convertible {
  display: block;
}
.splitter--convertible > * + * {
  margin-top: 1.125rem;
}

@media (min-width: 48em) {
  .splitter--convertible {
    display: flex;
  }

  > * + * {
    margin-top: 0s;
  }
}
/* ==============================================================
  Layout - Wrapper
================================================================= */
.wrapper {
  margin: 0 auto;
  max-width: 1194px;
  width: 100%;
}

.wrapper--decorative {
  border-top: 1px solid #000000;
  padding-top: 2rem;
}

.wrapper--expanded {
  max-width: 1325px;
  width: 100%;
}

/* ==============================================================
  Object - Asset
================================================================= */
@media (min-width: 48em) {
  .asset {
    display: flex;
    justify-content: space-between;
  }
}

.asset .vr:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .asset__body {
    flex: 1;
    order: -1;
  }
}

.asset__media {
  margin-bottom: 1.5rem;
}
@media (min-width: 48em) {
  .asset__media {
    flex: 1;
    margin-bottom: 0;
    margin-left: 1.5rem;
  }
}

/* ==============================================================
  Object - Austere Link
================================================================= */
.austere-link {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.007142857em;
  line-height: 1.231;
  display: block;
  padding-left: 17px;
  position: relative;
  text-decoration: none;
}

.austere-link.is-active::before {
  content: "";
  background-color: #986e12;
  border-radius: 50%;
  display: block;
  height: 7px;
  left: 0;
  position: absolute;
  top: calc(50% - 2px);
  width: 7px;
}

/* ==============================================================
  Object - Block Navigation
================================================================= */
@media (min-width: 64em) {
  .block-navigation {
    align-items: center;
    display: flex;
  }
}

@media (min-width: 64em) {
  .block-navigation > *:first-child {
    margin-right: 5.3125rem;
  }
}

@media (min-width: 64em) {
  .block-navigation--inverted > *:first-child {
    margin-left: 5.3125rem;
    margin-right: 0;
  }
}

.block-navigation--inverted > *:last-child {
  order: -1;
}

/* ==============================================================
  Object - Branding
================================================================= */
.branding {
  width: 123px;
}

/* ==============================================================
  Object - Branding
================================================================= */
.button {
  appearance: none;
  background-color: transparent;
  border: 1px solid #000000;
  color: inherit;
  display: inline-block;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  padding: 0.58em 1.111111111em;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  user-select: none;
}
.button:hover {
  color: #ffffff;
  cursor: pointer;
}

.button__text {
  position: relative;
  z-index: 1;
}

.button:not(.button--stealth)::after {
  content: "";
  background-color: #000000;
  display: block;
  height: 100%;
  right: 100%;
  position: absolute;
  top: 0;
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
}

.button:hover::after {
  transform: translateX(100%);
}

.button--spectral {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.button--spectral:hover {
  color: #000000;
}
.button--spectral:not(.button--stealth)::after {
  background-color: #ffffff;
}

.button--stealth {
  background-color: transparent;
  border: none;
  font-size: initial;
  margin: 0;
  padding: 0;
}
.button--stealth:hover {
  background-color: transparent;
}

.button--with-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.button--with-icon__icon {
  margin-left: 0.5em;
  position: relative;
  width: 12px;
}
.button--with-icon__icon > svg path,
.button--with-icon__icon > svg circle,
.button--with-icon__icon > svg line {
  stroke: currentColor;
}

/* ==============================================================
  Object - Circle Brand
================================================================= */
.circle-brand {
  display: block;
  margin: 0 auto;
  max-width: 151px;
}
.circle-brand > svg circle {
  fill: #cc9c43;
}
@media (min-width: 48em) {
  .circle-brand {
    margin: 0;
  }
}

/* ==============================================================
  Object - Column Synopsis
================================================================= */
.column-synopsis {
  display: flex;
  flex-direction: column;
  min-height: 436px;
  height: 100%;
}
@media (min-width: 48em) {
  .column-synopsis {
    min-height: auto;
  }
}

.column-synopsis__meta {
  border-top: 1px solid #000000;
  padding-top: 1.25rem;
}

.column-synopsis__meta + * {
  margin-top: auto;
}

.column-synopsis p + p {
  margin-top: 1.5rem;
}

.column-synopsis__content a {
  color: #000000;
}

.column-synopsis__content a:hover {
  text-decoration: none;
}

/* ==============================================================
  Object - Control Link
================================================================= */
.control-link {
  align-items: center;
  color: #ffffff;
  display: inline-flex;
  text-decoration: none;
}

.control-link__icon {
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.control-link__icon > svg {
  width: 17px;
}
.control-link__icon > svg > path {
  stroke: currentColor;
}

.control-link > * + .control-link__icon {
  margin-left: 10px;
}

.control-link__icon + * {
  margin-left: 10px;
}

.control-link:hover > * + .control-link__icon {
  transform: translateX(5px);
}

.control-link:hover > .control-link__icon:first-child {
  transform: translateX(-5px);
}

/* ==============================================================
  Object - Crossfader
================================================================= */
.crossfader > * {
  height: 100vh;
  opacity: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
}

.crossfader > *:first-child {
  opacity: 1;
}

.crossfader.is-active > *:first-child > .lazyloaded + .dyad__media__content--faded {
  opacity: 1;
}

/* ==============================================================
  Object - Decorative Link
================================================================= */
.decorative-link {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01875em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  min-height: var(--masthead-height);
  position: relative;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.decorative-link:hover {
  color: #986e12;
}

.nav__list > li > ul > li > .decorative-link,
.decorative-link--block {
  justify-content: flex-start;
  min-height: auto;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 12px;
  text-align: left;
}

.decorative-link.is-active {
  color: #986e12;
}

.decorative-link.is-active::before {
  content: "";
  background-color: #986e12;
  border-radius: 50%;
  display: block;
  height: 7px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-34%);
  width: 7px;
}

/* ==============================================================
  Object - Dyad
================================================================= */
.dyad {
  display: flex;
  flex-direction: column;
}
@media (min-width: 48em) {
  .dyad {
    flex-direction: row;
  }
}

.dyad > * {
  position: relative;
}
@media (min-width: 48em) {
  .dyad > * {
    height: 100vh;
    width: 50%;
  }
}

@media (min-width: 48em) {
  .dyad--short > * {
    height: 89vh;
    max-height: 800px;
  }
}

@media (min-width: 48em) {
  .dyad--asymmetrical > * {
    height: 78vh;
    max-height: 700px;
  }
}

.dyad__media {
  display: none;
}
@media (min-width: 48em) {
  .dyad__media {
    display: grid;
    grid-template-areas: "dyadMedia";
    height: 100vh;
    overflow: hidden;
    place-items: center;
  }
  .dyad__media > * {
    grid-area: dyadMedia;
  }
}

.dyad__media--persist {
  display: grid;
  grid-template-areas: "dyadMedia";
  height: 324px;
  overflow: hidden;
  place-items: center;
}
.dyad__media--persist > * {
  grid-area: dyadMedia;
}
@media (min-width: 48em) {
  .dyad__media--persist {
    height: 100vh;
  }
}

@media (min-width: 48em) {
  .dyad--asymmetrical > *:first-child {
    width: 43.958333334%;
  }
}

@media (min-width: 48em) {
  .dyad--asymmetrical > *:last-child {
    width: 56.041666666%;
  }
}

.dyad__media--flourish {
  overflow: visible;
}

.dyad__media--flourish::before {
  content: "";
  bottom: 0;
  display: block;
  background-image: url("../media/images/media-flourish.svg");
  background-repeat: no-repeat;
  left: 50%;
  position: absolute;
  height: 127px;
  width: 333px;
  transform: translate(-50%, 50%);
  z-index: 1;
}

.dyad--short .dyad__media {
  height: 89vh;
  max-height: 800px;
}

.dyad__media__media {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.dyad--short .dyad__media__media {
  max-height: 800px;
}

.dyad__media__content--faded {
  opacity: 0;
  transition: opacity 800ms linear 550ms;
}

.fader-is-active .dyad__media__content--faded {
  opacity: 1;
}

/* ==============================================================
  Object - Dynamic Bars
================================================================= */
.dynamic-bars {
  display: inline-flex;
  height: 14px;
  position: relative;
  width: 22px;
}

.dynamic-bars__elements {
  background-color: #000000;
  height: 1px;
  left: 0;
  position: absolute;
  top: 7px;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 22px;
}

button[aria-expanded=true] .dynamic-bars__elements {
  transform: rotate(45deg);
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
}

.dynamic-bars__elements::before,
.dynamic-bars__elements::after {
  content: "";
  background-color: #000000;
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 22px;
}

.dynamic-bars__elements::before {
  top: -7px;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dynamic-bars__elements::after {
  bottom: -7px;
  transition: bottom 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

button[aria-expanded=true] .dynamic-bars__elements::before {
  opacity: 0;
  transform: translateY(7px);
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

button[aria-expanded=true] .dynamic-bars__elements::after {
  bottom: 0;
  transition: bottom 0.1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transform: rotate(-90deg);
}

/* ==============================================================
  Object - Feature
================================================================= */
.feature__media {
  display: block;
  position: relative;
}
.feature__media::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.6666666667%;
}
.feature__media img {
  left: 0;
  position: absolute;
  top: 0;
}

.feature__placeholder {
  align-items: center;
  background-color: #1a216f;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 100%;
}
.feature__placeholder > svg {
  width: 46.376811594%;
}

.feature__placeholder--light-red {
  background-color: #ff7342;
}
.feature__placeholder--light-red > svg path {
  fill: #664300;
}

.feature__placeholder--light-blue {
  background-color: #7094e2;
}
.feature__placeholder--light-blue > svg path {
  fill: #4f499e;
}

.feature__placeholder--light-mustard {
  background-color: #cc9c43;
}
.feature__placeholder--light-mustard > svg path {
  fill: #367b7e;
}

.feature__placeholder--light-green {
  background-color: #67aaad;
}
.feature__placeholder--light-green > svg path {
  fill: #3b67b0;
}

/* ==============================================================
  Object - Feature Hero
================================================================= */
.feature-hero {
  background-color: #000000;
  display: grid;
  grid-template-areas: "hero";
  height: 80vh;
  max-height: 720px;
  overflow: hidden;
  place-items: center left;
}
.feature-hero > * {
  grid-area: hero;
}

.feature-hero--short {
  height: 70vh;
  max-height: 600px;
}

.feature-hero__content {
  justify-self: center;
  max-width: 1194px;
  width: 100%;
  z-index: 1;
}
@media (max-width: 74.625em) {
  .feature-hero__content {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}

.feature-hero__content-wrap {
  max-width: 293px;
}
@media (min-width: 31.25em) {
  .feature-hero__content-wrap {
    max-width: 888px;
  }
}

.feature-hero__media {
  height: 100%;
  max-height: 720px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  transform: translateY(var(--hero-transform));
  will-change: transform;
}

.feature-hero--short .feature-hero__media {
  max-height: 600px;
}

/* ==============================================================
  Object - Field
================================================================= */
.field {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #000000;
  line-height: 1.231;
  margin: 0;
  min-height: 43px;
  padding: 0;
  width: 100%;
}

.field--jumbo {
  border-bottom: none;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.field-context {
  position: relative;
}

.field-context--select::before {
  content: "";
  display: block;
  background-image: url("../media/images/down-angle.svg");
  background-repeat: no-repeat;
  height: 7px;
  position: absolute;
  right: 0.7rem;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  transition: filter 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 12px;
  will-change: filter;
  z-index: 1;
}

.field-context--select:hover::before {
  filter: brightness(0) invert(1);
}

.field-faux-select-label {
  background-color: #67aaad;
  border: 1px solid #000000;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  right: 0;
  text-align: center;
  top: 0;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.field-faux-select-label > span {
  position: relative;
  z-index: 1;
}

.field-faux-select-label--mustard {
  background-color: #cc9c43;
}

.field-faux-select-label::before {
  content: "";
  background-color: #000000;
  display: block;
  height: 100%;
  right: 100%;
  position: absolute;
  top: 0;
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
}

.field-context--select:hover .field-faux-select-label::before {
  transform: translateX(100%);
}

.field-context:hover .field-faux-select-label {
  color: #ffffff;
}

.field--select {
  appearance: none;
  background-image: linear-gradient(to right, #000000, #000000 50%, #67aaad 50%, #67aaad);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 200% 200%;
  border: 1px solid #000000;
  border-radius: 0;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 1.5rem;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1), background-position 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.field--select:hover {
  background-position: 0;
  color: #ffffff;
  cursor: pointer;
}
@media (min-width: 48em) {
  .field--select {
    padding: 0 2rem;
  }
}

.field--select--mustard {
  background-image: linear-gradient(to right, #000000, #000000 50%, #cc9c43 50%, #cc9c43);
}

/* ==============================================================
  Object - Filter Action
================================================================= */
.filter-action {
  color: #000000;
  display: inline-block;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.25rem 1.125rem;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.filter-action__icon {
  display: none;
  margin-left: 5px;
  vertical-align: middle;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 11px;
}
.filter-action__icon > svg path {
  stroke: currentColor;
  width: 100%;
}

.filter-action.is-active,
.filter-action:hover {
  background-color: #986e12;
  color: #ffffff;
}

.filter-action.is-active .filter-action__icon {
  display: inline-block;
}

/* ==============================================================
  Object - Filter Controls
================================================================= */
.filter-controls {
  display: flex;
  flex-direction: column;
}

.filter-controls--offset {
  overflow: auto;
  padding: 4rem 1.8rem;
}
@media (min-width: 80em) {
  .filter-controls--offset {
    padding: 6.125rem 7.1875rem;
  }
}

.filter-controls__nav {
  align-items: center;
  border-top: 1px solid #000000;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
}

.filter-controls__actions {
  display: flex;
}

.filter-controls__actions > * {
  border-left: 1px solid #000000;
  min-width: 112px;
}

.filter-controls__list {
  column-count: 4;
  column-gap: 20px;
  column-width: 200px;
  list-style: none;
  padding: 2.8rem 0 0 0;
  margin: 0;
}
@media (min-width: 48em) {
  .filter-controls__list {
    margin-left: -1.125rem;
  }
}

.filter-controls__list > * {
  margin: 0.3rem 0;
}

.filter-controls__stack {
  list-style: none;
  margin: auto 0 0 0;
  padding: 0;
}

.filter-controls__stack > * + * {
  margin-top: 0.4rem;
}

/* ==============================================================
  Object - Filter Dispatcher
================================================================= */
.filter-dispatcher {
  border-bottom: 1px solid #367b7e;
  color: #000000;
  display: inline-block;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.005555555em;
  padding-bottom: 1px;
  position: relative;
  text-decoration: none;
}
@media (min-width: 48em) {
  .filter-dispatcher {
    font-size: 2.25rem;
  }
}

.filter-dispatcher--dark {
  border-bottom-color: #986e12;
}

.filter-dispatcher::before {
  content: "";
  display: block;
  background-color: #ffffff;
  border-radius: 50%;
  height: 9px;
  left: -23px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 9px;
}

.filter-dispatcher:hover::before,
.filter-dispatcher.is-active::before {
  opacity: 1;
}

/* ==============================================================
  Object - Footer
================================================================= */
.footer {
  background-color: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 498px;
  padding-bottom: 1.125rem;
  padding-left: 1.125rem;
  padding-top: 3.75rem;
  padding-right: 1.125rem;
}
@media (min-width: 48em) {
  .footer {
    padding-top: 7.125rem;
  }
}

/* ==============================================================
  Object - Header Block
================================================================= */
.header-block {
  border-bottom: 1px solid #000000;
  padding-bottom: 1.8rem;
}

.header-block > *:last-of-type {
  margin-bottom: 0;
}

/* ==============================================================
  Object - Hero
================================================================= */
.hero {
  position: relative;
}
.hero::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 41.6666666667%;
}

.hero--constrained {
  max-height: 597px;
  overflow: hidden;
}

.hero__media {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

/* ==============================================================
  Object - Highlight Link
================================================================= */
.highlight-link {
  color: #cc4e13;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01875em;
  line-height: 1.25;
  text-decoration: none;
  align-items: center;
  display: inline-flex;
}
.highlight-link:hover {
  text-decoration: underline;
}

.highlight-link__icon {
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.highlight-link__icon > svg {
  position: relative;
  top: 2px;
  width: 12px;
}
.highlight-link__icon > svg > path {
  stroke: currentColor;
}

.highlight-link > * + .highlight-link__icon {
  margin-left: 10px;
}

.highlight-link__icon + * {
  margin-left: 10px;
}

.highlight-link:hover > * + .highlight-link__icon {
  transform: translateX(5px);
}

.highlight-link:hover > .highlight-link__icon:first-child {
  transform: translateX(-5px);
}

/* ==============================================================
  Object - Illustrative Stack
================================================================= */
.illustrative-stack__media {
  margin-bottom: 6rem;
  width: 296px;
}
.illustrative-stack__media > svg {
  width: 100%;
}

.illustrative-stack__label {
  text-align: center;
}

/* ==============================================================
  Object - Index Link
================================================================= */
.index-link {
  color: #000000;
  display: block;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  height: 40px;
  line-height: 1.231;
  text-align: center;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 40px;
}

.index-link > * {
  border-bottom: 1px solid transparent;
  pointer-events: none;
  vertical-align: middle;
  transition: border-color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.index-link.is-active,
.index-link:hover {
  color: #cc4e13;
}
.index-link.is-active > *,
.index-link:hover > * {
  border-color: #cc4e13;
}

/* ==============================================================
  Object - Masthead
================================================================= */
.masthead {
  background-color: #ffffff;
  border-bottom: 1px solid #000000;
  position: fixed;
  width: 100vw;
  z-index: 10;
}
@media (max-width: 82.8025em) {
  .masthead {
    padding-left: 1.125rem;
  }
}

.masthead__wrapper {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
}

.masthead__wrapper--ghost {
  background-color: #ffffff;
  border-bottom: none;
  height: 100%;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  z-index: 2;
}

.masthead__wrapper--ghost.is-present {
  opacity: 1;
  pointer-events: all;
}

.masthead__wrapper > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--masthead-height);
  text-align: center;
}

.masthead__wrapper > * + * {
  border-left: 1px solid #000000;
}

.masthead__wrapper > *:nth-child(1) {
  flex: 1;
  padding-right: 2.5rem;
}
@media (min-width: 80em) {
  .masthead__wrapper > *:nth-child(1) {
    flex: 0 1 15.622641509%;
    padding-left: 4.6875rem;
  }
}

.masthead__wrapper > *:nth-child(2) {
  flex: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
@media (min-width: 80em) {
  .masthead__wrapper > *:nth-child(2) {
    flex: 0 1 72.490566037%;
  }
}

.masthead__wrapper > *:nth-child(3) {
  flex: 1;
}
@media (min-width: 80em) {
  .masthead__wrapper > *:nth-child(3) {
    flex: 0 1 11.547169811%;
  }
}

.masthead__wrapper > *:nth-child(4) {
  flex: 1;
}
@media (min-width: 80em) {
  .masthead__wrapper > *:nth-child(4) {
    flex: 0 1 11.849056603%;
  }
}

.masthead__wrapper > *:nth-child(5) {
  flex: 1;
}
@media (min-width: 64em) {
  .masthead__wrapper > *:nth-child(5) {
    flex: auto;
  }
}

.masthead__wrapper--ghost > *:first-child {
  flex: 0 1 76.566037735%;
  padding-left: 15.622641509%;
}

.masthead__wrapper--ghost > * + *:nth-child(2) {
  flex: 0 1 11.547169811%;
}

.masthead__wrapper--ghost > * + *:nth-child(3) {
  flex: 0 1 11.849056603%;
}

/* ==============================================================
  Object - Media Flourish
================================================================= */
.media-flourish {
  position: relative;
}

.media-flourish::before {
  content: "";
  display: block;
  background-image: url("../media/images/media-flourish.svg");
  background-position: left top;
  background-repeat: no-repeat;
  bottom: 0;
  height: 99px;
  left: -15px;
  position: absolute;
  transform: translateY(30%);
  width: 227px;
  z-index: 1;
}
@media (min-width: 48em) {
  .media-flourish::before {
    height: 134px;
    width: 306px;
  }
}

/* ==============================================================
  Object - Mini Controls
================================================================= */
.mini-controls {
  align-items: center;
  display: flex;
}

.mini-controls__action {
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  user-select: none;
  height: 40px;
  width: 40px;
}
.mini-controls__action:hover {
  cursor: pointer;
}

.mini-controls__icon > svg {
  margin: 0 auto;
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 17px;
}
.mini-controls__icon > svg > path {
  stroke: #cc4e13;
}

.mini-controls__action:first-child:hover .mini-controls__icon > svg {
  transform: translateX(-5px);
}

.mini-controls__action:last-child:hover .mini-controls__icon > svg {
  transform: translateX(5px);
}

/* ==============================================================
  Object - Nav List
================================================================= */
.nav {
  padding-bottom: 0;
  padding-top: 0;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__list {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.nav__list > * {
  position: relative;
}

.nav__list > * > ul {
  background-color: #ffffff;
  border: 1px solid #000000;
  left: -999em;
  min-width: 198px;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.nav__list > *:hover > ul {
  left: 0;
  opacity: 1;
}

.nav__list > * > * > * + * {
  border-top: 1px solid #000000;
}

.nav__list > * + * {
  margin-left: 34px;
}
@media (min-width: 80em) {
  .nav__list > * + * {
    margin-left: 54px;
  }
}

/* ==============================================================
  Object - Overlay
================================================================= */
.overlay {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding-bottom: 2rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-top: 2rem;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  z-index: 40;
}
@media (min-width: 48em) {
  .overlay {
    padding-bottom: 5.0625rem;
    padding-top: 5.0625rem;
  }
}

.overlay--neutral {
  padding: 0;
}

.overlay.is-active {
  opacity: 1;
  pointer-events: all;
}

.overlay--light-mustard {
  background-color: #cc9c43;
}

.overlay--light-green {
  background-color: #67aaad;
}

/* ==============================================================
  Object - Pagination
================================================================= */
.pagination {
  display: flex;
  justify-content: center;
}

.pagination > * {
  display: flex;
  padding-left: 56px;
  padding-right: 56px;
  position: relative;
}

.pagination__control {
  left: 0;
  position: absolute;
  top: 0;
}

.pagination__control:last-child {
  left: auto;
  right: 0;
}

.pagination__action {
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  user-select: none;
  height: 40px;
  width: 40px;
}
.pagination__action:hover {
  cursor: pointer;
}

.pagination__action > * {
  pointer-events: none;
}

.pagination__icon > svg {
  margin: 0 auto;
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 17px;
}
.pagination__icon > svg > path {
  stroke: #cc4e13;
}

.pagination__control:first-child .pagination__action:hover .pagination__icon > svg {
  transform: translateX(-5px);
}

.pagination__control:last-child .pagination__action:hover .pagination__icon > svg {
  transform: translateX(5px);
}

/* ==============================================================
  Object - Poem
================================================================= */
.poem {
  line-height: 1.6;
  padding-bottom: 3.5rem;
}

.skip-link {
  padding: 1em;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  border: 3px solid;
  background-color: #ffffff;
}
.skip-link:focus {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
.skip-link:focus:not(:hover) {
  outline: 1px dotted;
  outline-offset: -0.5em;
}

/* ==============================================================
  Object - Section
================================================================= */
.section {
  position: relative;
}

.section--light-red {
  background-color: #ff7342;
}

.section--light-blue {
  background-color: #7094e2;
}

.section--light-green {
  background-color: #67aaad;
}

.section--light-mustard {
  background-color: #cc9c43;
}

.section--dark-purple {
  background-color: #1a216f;
}

.section--lightest {
  background-color: #ffffff;
}

.section--text {
  background-color: #ffffff;
}

.section--split-white {
  display: flex;
  min-height: 300px;
  position: relative;
}
.section--split-white::after {
  content: "";
  display: block;
  background-color: #ffffff;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
}
.section--split-white > .wrapper {
  display: flex;
}

.section--insulated {
  padding-bottom: 2.3125rem;
  padding-left: 1.125rem;
  padding-top: 2.3125rem;
  padding-right: 1.125rem;
}
@media (min-width: 48em) {
  .section--insulated {
    padding-bottom: 5.3125rem;
    padding-top: 5.3125rem;
  }
}

.section--insulated.section--with-flourish-center {
  padding-top: 8.5rem;
}

@media (min-width: 48em) {
  .section--insulated--cozy {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
  }
}

.section--minor-pad {
  padding-bottom: 2.3125rem;
  padding-left: 1.125rem;
  padding-top: 2.3125rem;
  padding-right: 1.125rem;
}
@media (min-width: 48em) {
  .section--minor-pad {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
  }
}

.section--median {
  padding-bottom: 2.3125rem;
  padding-left: 1.125rem;
  padding-top: 2.3125rem;
  padding-right: 1.125rem;
}
@media (min-width: 48em) {
  .section--median {
    padding-bottom: 3.125rem;
    padding-top: 3.125rem;
  }
}

.section--short {
  padding-bottom: 2.875rem;
  padding-left: 1.125rem;
  padding-top: 2.875rem;
  padding-right: 1.125rem;
}

.section--minimal {
  padding-bottom: 2.875rem;
  padding-left: 1.125rem;
  padding-top: 1.5rem;
  padding-right: 1.125rem;
}
@media (min-width: 48em) {
  .section--minimal {
    padding-bottom: 12rem;
  }
}

.section--with-flourish::before {
  content: "";
  background-repeat: no-repeat;
  display: block;
  top: 100%;
  left: 1.125rem;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
}

.section--with-flourish--extreme::before {
  top: 107%;
}
@media (min-width: 31.25em) {
  .section--with-flourish--extreme::before {
    top: 125%;
  }
}

.section--with-flourish-center::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section--with-flourish-right::before {
  left: auto;
  right: 1.125rem;
}

.section--with-flourish-2::before {
  background-image: url("../media/images/flourish-2.svg");
  height: 92px;
  width: 42px;
}
@media (min-width: 31.25em) {
  .section--with-flourish-2::before {
    height: 184px;
    width: 84px;
  }
}

.section--with-wrapper-flourish::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  bottom: 0;
  left: 1.125rem;
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  z-index: 1;
}
@media (min-width: 74.625em) {
  .section--with-wrapper-flourish::before {
    left: calc(50% - (1194px / 2));
  }
}

.section--with-wrapper-flourish-right::before {
  bottom: auto;
  top: 97%;
  left: auto;
  right: -15px;
}
@media (min-width: 74.625em) {
  .section--with-wrapper-flourish-right::before {
    right: -44px;
  }
}

.section--with-flourish-1::before {
  background-image: url("../media/images/flourish-1.svg");
  height: 70px;
  width: 117px;
}
@media (min-width: 48em) {
  .section--with-flourish-1::before {
    height: 140px;
    width: 233px;
  }
}

.section--with-flourish-3::before {
  background-image: url("../media/images/flourish-3.svg");
  height: 64px;
  width: 167px;
}
@media (min-width: 48em) {
  .section--with-flourish-3::before {
    height: 127px;
    width: 333px;
  }
}

.section--with-flourish-4::before {
  background-image: url("../media/images/flourish-4.svg");
  height: 73px;
  width: 170px;
}
@media (min-width: 48em) {
  .section--with-flourish-4::before {
    height: 146px;
    width: 340px;
  }
}

.section--with-flourish-5::before {
  background-image: url("../media/images/flourish-5.svg");
  height: 105px;
  transform: translateY(-70%);
  width: 117px;
}
@media (min-width: 48em) {
  .section--with-flourish-5::before {
    height: 180px;
    transform: translateY(-60%);
    width: 200px;
  }
}

.section--longFormText .wrapper > * + * {
  margin-top: 2rem;
}
@media (min-width: 48em) {
  .section--longFormText .wrapper > * + * {
    margin-top: 3.5rem;
  }
}

.section--textPreamble {
  padding-bottom: 3rem;
  padding-top: 0;
}
@media (min-width: 48em) {
  .section--textPreamble .grid > * {
    grid-column: 1/span 6;
  }
}

.section--longFormText .section--textPreamble {
  margin: 0 auto;
  max-width: 785px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (min-width: 48em) {
  .section--longFormText .section--textPreamble .grid > * {
    grid-column: 1/span 12;
  }
}

.section--featureHero + .section--longFormText {
  padding-top: 2.875rem;
}
@media (min-width: 48em) {
  .section--featureHero + .section--longFormText {
    padding-top: 5rem;
  }
}

.section--featureHero + .section--textPreamble {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-top: 5rem;
}

.section__scroll-offset-trigger {
  bottom: 0;
  right: 0;
  top: calc(var(--masthead-height) * -1);
  left: 0;
  position: absolute;
}

.section--loader {
  position: relative;
}

.section--loader::before {
  content: "";
  background-color: #ffffff;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  z-index: 10;
}

.section--loader.is-loading::before {
  opacity: 0.91;
  pointer-events: all;
}

.section--poetSlider,
.section--themeSlider,
.section--recentColumns,
.section--featuredItemSlider {
  overflow-x: hidden;
}

@media (min-width: 48em) {
  .section--browsePoems + .section--featuredItemSlider {
    padding-top: 7rem;
  }
}

/* ==============================================================
  Object - Site
================================================================= */
.site {
  padding-top: var(--masthead-height);
}

/* ==============================================================
  Object - Small Nav
================================================================= */
.small-nav {
  background-color: #ffffff;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: calc(var(--masthead-height) + 1px);
  transition: height 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
}

.small-nav__container {
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}

.small-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.small-nav__list > * {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.small-nav__list > * + * {
  border-top: 1px solid #000000;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

/* ==============================================================
  Object - Sneezeguard
================================================================= */
.sneezeguard {
  background-color: #000000;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  z-index: 10;
}

@media (min-width: 48em) {
  .sneezeguard {
    z-index: 20;
  }
}
.sneezeguard.is-present {
  opacity: 0.3;
  pointer-events: all;
}

/* ==============================================================
  Object - Text Link
================================================================= */
.text-link {
  appearance: none;
  background-color: transparent;
  border: none;
  display: inline-block;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  user-select: none;
}
.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-link--highlight {
  color: #cc4e13;
  font-weight: 500;
}

.text-link--wide {
  width: 100%;
}

.text-link--nav {
  min-height: var(--masthead-height);
}

.text-link--filter-nav {
  min-height: 48px;
}

.text-link--nav:hover {
  color: #986e12;
  text-decoration: none;
}

.text-link--with-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.text-link--with-icon--flush-right {
  justify-content: flex-end;
}

.text-link__icon {
  margin-left: 0.5625rem;
  position: relative;
  width: 12px;
}
.text-link__icon > svg path,
.text-link__icon > svg circle,
.text-link__icon > svg line {
  stroke: currentColor;
}

.text-link__icon--tweaked {
  top: 2px;
}

.text-link__icon--proximate {
  margin-left: 0.3rem;
}

.text-link__isolated-icon {
  display: flex;
  justify-content: center;
  width: 100%;
}
.text-link__isolated-icon > svg {
  stroke: currentColor;
  width: 20px;
}
@media (min-width: 64em) {
  .text-link__isolated-icon {
    display: block;
    margin-right: 1.0625rem;
    margin-top: 2px;
    width: 15px;
  }
}

.text-link__icon:first-child {
  margin-left: 0;
  margin-right: 1.0625rem;
}

.text-link__icon--proximate:first-child {
  margin-right: 0.7rem;
}

/* ==============================================================
  Object - Text Stack
================================================================= */
.text-stack__heading--constrained {
  max-width: 267px;
}

/* stylelint-disable */
/* ==============================================================
  Object - User Content
================================================================= */
.user-content {
  color: #000000;
  font-family: Ogg, Georgia, Times, "Times New Roman", serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
}
.user-content > *:first-child {
  margin-top: 0 !important;
}
.user-content > *:last-child {
  margin-bottom: 0 !important;
}
.user-content h1,
.user-content h2,
.user-content h3,
.user-content h4,
.user-content h5 {
  margin-bottom: 0.8em;
  margin-top: 1.5em;
}
.user-content h1 {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: -0.004166666em;
  line-height: 1;
}
@media (min-width: 48em) {
  .user-content h1 {
    font-size: 3rem;
  }
}
.user-content h2 {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -0.005555555em;
  line-height: 1;
}
.user-content h2 > a {
  color: #000000;
  text-decoration: none;
}
.user-content h2 > a:hover {
  text-decoration: underline;
}
@media (min-width: 48em) {
  .user-content h2 {
    font-size: 2.25rem;
  }
}
.user-content h3 {
  color: #000000;
  font-family: FoundersGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.125;
  text-decoration: none;
}
@media (min-width: 48em) {
  .user-content h3 {
    font-size: 1.5rem;
  }
}
.user-content figure {
  display: block;
  margin: 0;
}
.user-content p {
  margin-bottom: 1.5rem;
}
.user-content a {
  color: #cc4e13;
  padding-bottom: 0;
}
.user-content blockquote {
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1.2rem;
  border-left: 5px solid;
  padding: 1rem 1.2rem;
  font-family: inherit;
  font-size: 1.1rem;
  font-style: italic;
}
.user-content blockquote > *:last-child {
  margin-bottom: 0;
  /* 3 */
}
.user-content figcaption {
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.3rem;
}
.user-content ul,
.user-content ol {
  margin-bottom: 2rem;
}
.user-content ul {
  list-style-type: disc;
}
.user-content ol {
  list-style-type: decimal;
}
.user-content ul ul,
.user-content ul ol,
.user-content ol ul,
.user-content ol ol {
  margin-bottom: 0;
}
.user-content ol ul,
.user-content ul ul {
  list-style-type: circle;
}
.user-content ol ol ul,
.user-content ol ul ul,
.user-content ul ol ul,
.user-content ul ul ul {
  list-style-type: square;
}
.user-content ul > li + li,
.user-content ol > li + li {
  margin-top: 1rem;
}
.user-content ul > li > ul > li + li,
.user-content ol > li > ol > li + li {
  margin-top: 0.3rem;
}
.user-content ul > li > ul,
.user-content ol > li > ol {
  margin-top: 0.5rem;
}
.user-content table {
  width: 100% !important;
  margin-bottom: 1.7rem;
  border-spacing: 0;
  border-collapse: collapse;
}
.user-content caption {
  font-size: smaller;
  padding: 10px 12px;
  text-align: center;
}
.user-content tr {
  vertical-align: middle;
}
.user-content tbody {
  vertical-align: middle;
}
.user-content thead {
  vertical-align: middle;
}
.user-content tfoot {
  vertical-align: middle;
}
.user-content td {
  vertical-align: inherit;
  text-align: left;
  padding: 4px 12px;
  border: 1px solid #3b67b0;
}
.user-content th {
  vertical-align: inherit;
  text-align: left;
  font-weight: bold;
  padding: 8px 12px;
  border: 1px solid #3b67b0;
  background-color: transparent;
}

.user-content--constrained {
  max-width: 553px;
}

.section--longFormText .content-constrain > .section + .section {
  margin-top: 2.5rem;
}

.user-content + .user-content__media,
.user-content__media + .user-content__media,
.user-content__media + .user-content,
.user-content + .user-content,
.user-content + .callout {
  margin-top: 2.5rem;
}

.user-content__media > figure {
  position: relative;
}

.user-content__media > figure > img {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.user-content--mini h4 {
  margin-bottom: 0;
  margin-top: 0;
}
.user-content--mini p {
  margin-bottom: 1.5rem;
}

.u-align-right {
  text-align: right;
}

.fade-stagger {
  opacity: 0;
  transform: translateY(-30px);
  transition-duration: 800ms;
  transition-property: opacity, transform;
  transition-timing-function: linear;
  will-change: opacity, transform;
}

.element-in-view.child-image-loaded .fade-stagger {
  opacity: 1;
  transform: translateY(0);
}

.u-flex {
  display: flex;
}

.u-flex--column {
  flex-direction: column;
}

.u-flex--1 {
  flex: 1;
}

.u-flex--space-between {
  justify-content: space-between;
}

.u-flex-invert {
  order: -1;
}

.u-flex--column-center {
  align-items: center;
}

.u-flex--column-bottom {
  margin-top: auto;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity 0.3s;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s;
}

.u-scroll-lock {
  overflow: hidden;
}

.u-scroll-target {
  scroll-margin-top: calc(var(--masthead-height) + .3rem);
}

.u-scroll-target-flush {
  scroll-margin-top: var(--masthead-height);
}

.u-text-constraint {
  max-width: 714px;
}

.u-image-constraint {
  max-width: 276px;
}

.is-visually-hidden, .skip-link:not(:focus) {
  position: absolute;
  width: 1px;
  height: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  border: 0;
}

.u-grayscale {
  filter: grayscale(1);
}

.is-hidden {
  visibility: hidden;
}

.is-absent {
  display: none;
}

.show-above-small-block,
.show-above-medium-block,
.show-above-medium-flex,
.show-above-large-flex,
.show-above-nav-flex,
.show-above-xxlarge-block {
  display: none;
}

@media (min-width: 31.25em) {
  .show-above-small-block {
    display: block;
  }
}
@media (min-width: 48em) {
  .hide-above-medium {
    display: none;
  }

  .show-above-medium-block {
    display: block;
  }

  .show-above-medium-flex {
    display: flex;
  }
}
@media (min-width: 64em) {
  .hide-above-large {
    display: none;
  }

  .show-above-large-flex {
    display: flex;
  }
}
@media (max-width: 47.99em) {
  .hide-below-medium {
    display: none;
  }

  .visually-hide-below-large {
    position: absolute;
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    border: 0;
  }
}
@media (max-width: 63.99em) {
  .visually-hide-below-large {
    position: absolute;
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    border: 0;
  }
}