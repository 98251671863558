/* ==============================================================
  Layout - Decorative Repeater
================================================================= */
.decorative-repeater {
  list-style: none;
  margin: 0;
  padding: 0;
}

.decorative-repeater > * + * {
  border-top: 1px solid $darkest;
  margin-top: 3.375rem;
  padding-top: 1.875rem;
}

.decorative-repeater--inverted > * {
  border-bottom: 1px solid $gray;
  padding-bottom: 2rem;
}

.decorative-repeater--inverted > * + * {
  border-top: none;
  margin-top: 2rem;
  padding-top: 0;
}
