/* ==============================================================
  Layout - VR
================================================================= */
.vr {
  margin-bottom: .25rem;
}

.vr--2x {
  margin-bottom: .5rem;
}

.vr--3x {
  margin-bottom: .75rem;
}

.vr--4x {
  margin-bottom: 1rem;
}

.vr--5x {
  margin-bottom: 1.25rem;
}

.vr--6x {
  margin-bottom: 1.5rem;
}

.vr--7x {
  margin-bottom: 1.75rem;
}

.vr--8x {
  margin-bottom: 2rem;
}

.vr--9x {
  margin-bottom: 2.25rem;
}

.vr--10x {
  margin-bottom: 2.5rem;
}

.vr--11x {
  margin-bottom: 2.75rem;
}

.vr--12x {
  margin-bottom: 3rem;
}

.vr--13x {
  margin-bottom: 3.25rem;
}

.vr--14x {
  margin-bottom: 3.5rem;
}

.vr--15x {
  margin-bottom: 3.75rem;
}

.vr--16x {
  margin-bottom: 4rem;
}

.vr--17x {
  margin-bottom: 4.25rem;
}

.vr--18x {
  margin-bottom: 4.5rem;
}

.vr--19x {
  margin-bottom: 4.75rem;
}

.vr--29x {
  margin-bottom: 7.25rem;
}

.vr--30x {
  margin-bottom: 7.5rem;
}

.vr--31x {
  margin-bottom: 7.75rem;
}

.vr--32x {
  margin-bottom: 8rem;
}

@include mq($from: medium) {
  .vr--cancel\@medium {
    margin-bottom: 0;
  }

  .vr--30x\@medium {
    margin-bottom: 7.5rem;
  }
}
