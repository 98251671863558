/* ==============================================================
  Object - Field
================================================================= */
.field {
  background: transparent;
  border: 0;
  border-bottom: 1px solid $darkest;
  line-height: 1.231;
  margin: 0;
  min-height: 43px;
  padding: 0;
  width: 100%;
}

.field--jumbo {
  border-bottom: none;
  font-family: $secondary;
  font-size: 1.5rem;
  font-weight: 500;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.field-context {
  position: relative;
}

.field-context--select::before {
  content: "";
  display: block;
  background-image: url("../media/images/down-angle.svg");
  background-repeat: no-repeat;
  height: 7px;
  position: absolute;
  right: .7rem;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  transition: filter $BASE_TEMPO $EASE_PRIMARY;
  width: 12px;
  will-change: filter;
  z-index: 1;
}

.field-context--select:hover::before {
  filter: brightness(0) invert(1);
}

.field-faux-select-label {
  background-color: $lightGreen;
  border: 1px solid $darkest;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  right: 0;
  text-align: center;
  top: 0;
  transition: color $BASE_TEMPO $EASE_PRIMARY;

  > span {
    position: relative;
    z-index: 1;
  }
}

.field-faux-select-label--mustard {
  background-color: $lightMustard;
}

.field-faux-select-label::before {
  content: "";
  background-color: $darkest;
  display: block;
  height: 100%;
  right: 100%;
  position: absolute;
  top: 0;
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
}

.field-context--select:hover .field-faux-select-label::before {
  transform: translateX(100%);
}

.field-context:hover .field-faux-select-label {
  color: $lightest;
}

.field--select {
  appearance: none;
  background-image: linear-gradient(to right, $darkest, $darkest 50%, $lightGreen 50%, $lightGreen);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 200% 200%;
  border: 1px solid $darkest;
  border-radius: 0;
  font-family: $secondary;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 1.5rem;
  transition:
    color $BASE_TEMPO $EASE_PRIMARY,
    background-position $BASE_TEMPO $EASE_PRIMARY;

  &:hover {
    background-position: 0;
    color: $lightest;
    cursor: pointer;
  }

  @include mq($from: medium) {
    padding: 0 2rem;
  }
}

.field--select--mustard {
  background-image: linear-gradient(to right, $darkest, $darkest 50%, $lightMustard 50%, $lightMustard);
}
