/* ==============================================================
  Layout - Wrapper
================================================================= */
.wrapper {
  margin: 0 auto;
  max-width: $constrainedWidth;
  width: 100%;
}

.wrapper--decorative {
  border-top: 1px solid $darkest;
  padding-top: 2rem;
}

.wrapper--expanded {
  max-width: $masthead;
  width: 100%;
}
