/* ==============================================================
  Object - Circle Brand
================================================================= */
.circle-brand {
  display: block;
  margin: 0 auto;
  max-width: 151px;

  > svg circle {
    fill: $lightMustard;
  }

  @include mq($from: medium) {
    margin: 0;
  }
}
