/* ==============================================================
  Object - Masthead
================================================================= */
.masthead {
  background-color: $lightest;
  border-bottom: 1px solid $darkest;
  position: fixed;
  width: 100vw;
  z-index: 10;

  @include mq($until: masthead) {
    padding-left: $wrapperOffset;
  }
}

.masthead__wrapper {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
}

.masthead__wrapper--ghost {
  background-color: $lightest;
  border-bottom: none;
  height: 100%;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
  z-index: 2;
}

.masthead__wrapper--ghost.is-present {
  opacity: 1;
  pointer-events: all;
}

.masthead__wrapper > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--masthead-height);
  text-align: center;
}

.masthead__wrapper > * + * {
  border-left: 1px solid $darkest;
}

.masthead__wrapper > *:nth-child(1) {
  flex: 1;
  padding-right: 2.5rem;

  @include mq($from: xlarge) {
    flex: 0 1 15.622641509%;
    padding-left: 4.6875rem;
  }
}

.masthead__wrapper > *:nth-child(2) {
  flex: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @include mq($from: xlarge) {
    flex: 0 1 72.490566037%;
  }
}

.masthead__wrapper > *:nth-child(3) {
  flex: 1;

  @include mq($from: xlarge) {
    flex: 0 1 11.547169811%;
  }
}

.masthead__wrapper > *:nth-child(4) {
  flex: 1;

  @include mq($from: xlarge) {
    flex: 0 1 11.849056603%;
  }
}

.masthead__wrapper > *:nth-child(5) {
  flex: 1;

  @include mq($from: large) {
    flex: auto;
  }
}

.masthead__wrapper--ghost > *:first-child {
  flex: 0 1 76.566037735%;
  padding-left: 15.622641509%;
}

.masthead__wrapper--ghost > * + *:nth-child(2) {
  flex: 0 1 11.547169811%;
}

.masthead__wrapper--ghost > * + *:nth-child(3) {
  flex: 0 1 11.849056603%;
}
