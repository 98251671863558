@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}
