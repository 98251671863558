/* ==============================================================
  Object - Control Link
================================================================= */
.control-link {
  align-items: center;
  color: $lightest;
  display: inline-flex;
  text-decoration: none;
}

.control-link__icon {
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
}

.control-link__icon > svg {
  width: 17px;

  > path {
    stroke: currentColor;
  }
}

.control-link > * + .control-link__icon {
  margin-left: 10px;
}

.control-link__icon + * {
  margin-left: 10px;
}

.control-link:hover > * + .control-link__icon {
  transform: translateX(5px);
}

.control-link:hover > .control-link__icon:first-child {
  transform: translateX(-5px);
}
