/* ==============================================================
  Layout - Carousel
================================================================= */
.carousel {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 95vw;

  @include mq($from: medium) {
    width: 70.208333333vw;
  }
}

.carousel.flickity-enabled {
  display: block;
}

.carousel__slide {
  opacity: .5;
  margin-right: 28px;
  min-width: 220px;
  transition: opacity linear .5s;
  width: 27.299703264%;
}

.carousel__slide.is-selected {
  opacity: 1;
}
