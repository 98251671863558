/* ==============================================================
  Layout - Grid
================================================================= */
.grid {
  display: grid;
  row-gap: $gapY;

  @include mq($from: medium) {
    column-gap: 30px;
    row-gap: unset;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.grid--sticky {
  align-items: baseline;
}

.grid--v-align {
  align-items: center;
}

.grid__col--insulated {
  padding-bottom: 0;
  padding-top: 1.875rem;

  @include mq($from: medium) {
    padding-bottom: 5.3125rem;
    padding-top: 3.75rem;
  }

  @include mq($until: constrainedWrapper) {
    padding-left: $wrapperOffset;
    padding-right: $wrapperOffset;
  }
}

@include mq($from: medium) {
  .grid__col--offset-minor {
    margin-top: 4.0625rem;
  }

  .grid__col {
    grid-row-start: 1;
  }

  .grid__col--start-1-span-2\@medium {
    grid-column: 1 / span 2;
  }

  .grid__col--start-1-span-3\@medium {
    grid-column: 1 / span 3;
  }

  .grid__col--start-1-span-4\@medium {
    grid-column: 1 / span 4;
  }

  .grid__col--start-1-span-5\@medium {
    grid-column: 1 / span 5;
  }

  .grid__col--start-1-span-6\@medium {
    grid-column: 1 / span 6;
  }

  .grid__col--start-3-span-3\@medium {
    grid-column: 3 / span 3;
  }

  .grid__col--start-4-span-3\@medium {
    grid-column: 4 / span 3;
  }

  .grid__col--start-4-span-6\@medium {
    grid-column: 4 / span 6;
  }

  .grid__col--start-4-span-9\@medium {
    grid-column: 4 / span 9;
  }

  .grid__col--start-6-span-5\@medium {
    grid-column: 6 / span 5;
  }

  .grid__col--start-6-span-7\@medium {
    grid-column: 6 / span 7;
  }

  .grid__col--start-7-span-3\@medium {
    grid-column: 7 / span 3;
  }

  .grid__col--start-7-span-5\@medium {
    grid-column: 7 / span 5;
  }

  .grid__col--start-7-span-6\@medium {
    grid-column: 7 / span 6;
  }

  .grid__col--start-10-span-3\@medium {
    grid-column: 10 / span 3;
  }

  .grid__col--start-11-span-2\@medium {
    grid-column: 11 / span 2;
  }

  .grid__col--image-offset {
    padding-top: 28px;
  }

  .grid__col--sticky {
    position: sticky;
    top: calc(var(--masthead-height) + 2rem);
  }
}

@include mq($until: medium) {
  .grid__col--invert\@medium {
    order: -1;
  }
}
