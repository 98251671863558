/* ==============================================================
  Object - Decorative Link
================================================================= */
.decorative-link {
  @include type-minimal();
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  min-height: var(--masthead-height);
  position: relative;
  text-decoration: none;
  transition: color $BASE_TEMPO $EASE_PRIMARY;

  &:hover {
    color: $mustard;
  }
}

.nav__list > li > ul > li > .decorative-link,
.decorative-link--block {
  justify-content: flex-start;
  min-height: auto;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 12px;
  text-align: left;
}

.decorative-link.is-active {
  color: $mustard;
}

.decorative-link.is-active::before {
  content: "";
  background-color: $mustard;
  border-radius: 50%;
  display: block;
  height: 7px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-34%);
  width: 7px;
}
