/* ==============================================================
  Object - Highlight Link
================================================================= */
.highlight-link {
  @include type-highlight();
  align-items: center;
  display: inline-flex;

  &:hover {
    text-decoration: underline;
  }
}

.highlight-link__icon {
  transition: transform $BASE_TEMPO $EASE_PRIMARY;
}

.highlight-link__icon > svg {
  position: relative;
  top: 2px;
  width: 12px;

  > path {
    stroke: currentColor;
  }
}

.highlight-link > * + .highlight-link__icon {
  margin-left: 10px;
}

.highlight-link__icon + * {
  margin-left: 10px;
}

.highlight-link:hover > * + .highlight-link__icon {
  transform: translateX(5px);
}

.highlight-link:hover > .highlight-link__icon:first-child {
  transform: translateX(-5px);
}
