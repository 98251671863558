/* ==============================================================
  Object - Filter Action
================================================================= */
.filter-action {
  color: $darkest;
  display: inline-block;
  font-family: $secondary;
  font-weight: 500;
  font-size: 1.5rem;
  padding: .25rem $filterListOffset;
  text-decoration: none;
  transition: background-color $BASE_TEMPO $EASE_PRIMARY;
}

.filter-action__icon {
  display: none;
  margin-left: 5px;
  vertical-align: middle;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
  width: 11px;

  > svg path {
    stroke: currentColor;
    width: 100%;
  }
}

.filter-action.is-active,
.filter-action:hover {
  background-color: #986e12;
  color: $lightest;
}

.filter-action.is-active .filter-action__icon {
  display: inline-block;
}
