.u-grayscale {
  filter: grayscale(1);
}

.is-visually-hidden {
  @extend %visually-hidden;
}

.is-hidden {
  visibility: hidden;
}

.is-absent {
  display: none;
}

.show-above-small-block,
.show-above-medium-block,
.show-above-medium-flex,
.show-above-large-flex,
.show-above-nav-flex,
.show-above-xxlarge-block {
  display: none;
}

@include mq($from: small) {
  .show-above-small-block {
    display: block;
  }
}

@include mq($from: medium) {
  .hide-above-medium {
    display: none;
  }

  .show-above-medium-block {
    display: block;
  }

  .show-above-medium-flex {
    display: flex;
  }
}

@include mq($from: large) {
  .hide-above-large {
    display: none;
  }

  .show-above-large-flex {
    display: flex;
  }
}

@include mq($until: medium) {
  .hide-below-medium {
    display: none;
  }

  .visually-hide-below-large {
    position: absolute;
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    border: 0;
  }
}

@include mq($until: large) {
  .visually-hide-below-large {
    position: absolute;
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    border: 0;
  }
}
