/* ==============================================================
  Object - Asset
================================================================= */
.asset {
  @include mq($from: medium) {
    display: flex;
    justify-content: space-between;
  }
}

.asset .vr:last-of-type {
  margin-bottom: 0;
}

.asset__body {
  @include mq($from: medium) {
    flex: 1;
    order: -1;
  }
}

.asset__media {
  margin-bottom: 1.5rem;

  @include mq($from: medium) {
    flex: 1;
    margin-bottom: 0;
    margin-left: 1.5rem;
  }
}
