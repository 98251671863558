.fade-stagger {
  opacity: 0;
  transform: translateY(-30px);
  transition-duration: 800ms;
  transition-property: opacity, transform;
  transition-timing-function: linear;
  will-change: opacity, transform;
}

.element-in-view.child-image-loaded {
  .fade-stagger {
    opacity: 1;
    transform: translateY(0);
  }
}
