/* ==============================================================
  Object - Small Nav
================================================================= */
.small-nav {
  background-color: $lightest;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: calc(var(--masthead-height) + 1px);
  transition: height $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
}

.small-nav__container {
  padding-bottom: .8rem;
  padding-top: .8rem;
}

.small-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.small-nav__list > * {
  padding-left: $wrapperOffset;
  padding-right: $wrapperOffset;
}

.small-nav__list > * + * {
  border-top: 1px solid $darkest;
  margin-top: .5rem;
  padding-top: .5rem;
}
