/* ==============================================================
  Layout - Splitter
================================================================= */
.splitter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.splitter--convertible {
  display: block;

  > * + * {
    margin-top: 1.125rem;
  }
}

@include mq($from: medium) {
  .splitter--convertible {
    display: flex;
  }

  > * + * {
    margin-top: 0s;
  }
}
