/* ==============================================================
  Object - Hero
================================================================= */
.hero {
  @include aspect-ratio(12, 5);
}

.hero--constrained {
  max-height: 597px;
  overflow: hidden;
}

.hero__media {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
