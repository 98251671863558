/* ==============================================================
  Object - Text Link
================================================================= */
.text-link {
  appearance: none;
  background-color: transparent;
  border: none;
  display: inline-block;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: color $BASE_TEMPO $EASE_PRIMARY;
  user-select: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.text-link--highlight {
  color: $red;
  font-weight: 500;
}

.text-link--wide {
  width: 100%;
}

.text-link--nav {
  min-height: var(--masthead-height);
}

.text-link--filter-nav {
  min-height: 48px;
}

.text-link--nav:hover {
  color: $mustard;
  text-decoration: none;
}

.text-link--with-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.text-link--with-icon--flush-right {
  justify-content: flex-end;
}

.text-link__icon {
  margin-left: .5625rem;
  position: relative;
  width: 12px;

  > svg path,
  > svg circle,
  > svg line {
    stroke: currentColor;
  }
}

.text-link__icon--tweaked {
  top: 2px;
}

.text-link__icon--proximate {
  margin-left: .3rem;
}

.text-link__isolated-icon {
  display: flex;
  justify-content: center;
  width: 100%;

  > svg {
    stroke: currentColor;
    width: 20px;
  }

  @include mq($from: large) {
    display: block;
    margin-right: 1.0625rem;
    margin-top: 2px;
    width: 15px;
  }
}

.text-link__icon:first-child {
  margin-left: 0;
  margin-right: 1.0625rem;
}

.text-link__icon--proximate:first-child {
  margin-right: .7rem;
}
