.skip-link {
  padding: 1em;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  border: 3px solid;
  background-color: #ffffff;

  &:not(:focus) {
    @extend %visually-hidden;
  }

  &:focus {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
  }

  &:focus:not(:hover) {
    outline: 1px dotted;
    outline-offset: -.5em;
  }
}
