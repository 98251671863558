// =============================================================================
// Sass MQ
// =============================================================================
$constrainedWidth: 1194px;
$masthead: 1325px;

$mq-breakpoints: (
  small: 500px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  constrainedWrapper: $constrainedWidth,
  wrapper: 1440px,
  masthead: $masthead
);

// =============================================================================
// Colors
// =============================================================================
$blue: #3b67b0;
$cream: #ebe7d9;
$darkBlue: #003d80;
$darkest: #000000;
$darkMustard: #664300;
$darkGreen: #004f52;
$darkPurple: #1a216f;
$darkRed: #941b00;
$green: #367b7e;
$lightBlue: #7094e2;
$lightest: #ffffff;
$lightGreen: #67aaad;
$lightMustard: #cc9c43;
$lightPurple: #8075d0;
$lightRed: #ff7342;
$mustard: #986e12;
$neon: #ffe700;
$purple: #4f499e;
$red: #cc4e13;
$gray: #999999;

// =============================================================================
// Font Stacks
// =============================================================================
$oggFallbacks: Georgia, Times, "Times New Roman", serif;
$primary: Ogg, $oggFallbacks;

$foundersGroteskFallbacks: "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondary: FoundersGrotesk, $foundersGroteskFallbacks;

// =============================================================================
// Layout Globals
// =============================================================================
$contentConstraint: 785px;
$gapY: 3.125rem;
$wrapperOffset: 1.125rem;
$filterListOffset: 1.125rem;

// ----------------------------------------------------------------------------
// Animation properties
//-----------------------------------------------------------------------------
$EASE_OUT_CUBIC: cubic-bezier(.215, .61, .355, 1);
$BASE_TEMPO: 250ms;
$EASE_PRIMARY: $EASE_OUT_CUBIC;
