/* ==============================================================
  Object - Feature
================================================================= */
.feature__media {
  display: block;
  @include aspect-ratio(3, 2);

  img {
    left: 0;
    position: absolute;
    top: 0;
  }
}

.feature__placeholder {
  align-items: center;
  background-color: $darkPurple;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 100%;

  > svg {
    width: 46.376811594%;
  }
}

.feature__placeholder--light-red {
  background-color: $lightRed;

  > svg path {
    fill: $darkMustard;
  }
}

.feature__placeholder--light-blue {
  background-color: $lightBlue;

  > svg path {
    fill: $purple;
  }
}

.feature__placeholder--light-mustard {
  background-color: $lightMustard;

  > svg path {
    fill: $green;
  }
}

.feature__placeholder--light-green {
  background-color: $lightGreen;

  > svg path {
    fill: $blue;
  }
}
