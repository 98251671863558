/* ==============================================================
  Layout - Control Group
================================================================= */
.control-group {
  display: flex;
}

.control-group > * + * {
  margin-left: 12px;
}

.control-group > *:first-child {
  flex: 1;
}
