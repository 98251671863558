.u-flex {
  display: flex;
}

.u-flex--column {
  flex-direction: column;
}

.u-flex--1 {
  flex: 1;
}

.u-flex--space-between {
  justify-content: space-between;
}

.u-flex-invert {
  order: -1;
}

.u-flex--column-center {
  align-items: center;
}

.u-flex--column-bottom {
  margin-top: auto;
}
