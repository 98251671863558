/* ==============================================================
  Object - Illustrative Stack
================================================================= */
.illustrative-stack__media {
  margin-bottom: 6rem;
  width: 296px;

  > svg {
    width: 100%;
  }
}

.illustrative-stack__label {
  text-align: center;
}
