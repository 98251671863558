/* ==============================================================
  Object - Section
================================================================= */
.section {
  position: relative;
}

.section--light-red {
  background-color: $lightRed;
}

.section--light-blue {
  background-color: $lightBlue;
}

.section--light-green {
  background-color: $lightGreen;
}

.section--light-mustard {
  background-color: $lightMustard;
}

.section--dark-purple {
  background-color: $darkPurple;
}

.section--lightest {
  background-color: $lightest;
}

.section--text {
  background-color: $lightest;
}

.section--split-white {
  display: flex;
  min-height: 300px;
  position: relative;

  &::after {
    content: "";
    display: block;
    background-color: $lightest;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
  }

  > .wrapper {
    display: flex;
  }
}

.section--insulated {
  padding-bottom: 2.3125rem;
  padding-left: $wrapperOffset;
  padding-top: 2.3125rem;
  padding-right: $wrapperOffset;

  @include mq($from: medium) {
    padding-bottom: 5.3125rem;
    padding-top: 5.3125rem;
  }
}

.section--insulated.section--with-flourish-center {
  padding-top: 8.5rem;
}

.section--insulated--cozy {
  @include mq($from: medium) {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
  }
}

.section--minor-pad {
  padding-bottom: 2.3125rem;
  padding-left: $wrapperOffset;
  padding-top: 2.3125rem;
  padding-right: $wrapperOffset;

  @include mq($from: medium) {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
  }
}

.section--median {
  padding-bottom: 2.3125rem;
  padding-left: $wrapperOffset;
  padding-top: 2.3125rem;
  padding-right: $wrapperOffset;

  @include mq($from: medium) {
    padding-bottom: 3.125rem;
    padding-top: 3.125rem;
  }
}

.section--short {
  padding-bottom: 2.875rem;
  padding-left: $wrapperOffset;
  padding-top: 2.875rem;
  padding-right: $wrapperOffset;
}

.section--minimal {
  padding-bottom: 2.875rem;
  padding-left: $wrapperOffset;
  padding-top: 1.5rem;
  padding-right: $wrapperOffset;

  @include mq($from: medium) {
    padding-bottom: 12rem;
  }
}

.section--with-flourish::before {
  content: "";
  background-repeat: no-repeat;
  display: block;
  top: 100%;
  left: $wrapperOffset;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
}

.section--with-flourish--extreme::before {
  top: 107%;

  @include mq($from: small) {
    top: 125%;
  }
}

.section--with-flourish-center::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section--with-flourish-right::before {
  left: auto;
  right: $wrapperOffset;
}

.section--with-flourish-2::before {
  background-image: url("../media/images/flourish-2.svg");
  height: 92px;
  width: 42px;

  @include mq($from: small) {
    height: 184px;
    width: 84px;
  }
}

.section--with-wrapper-flourish::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  bottom: 0;
  left: $wrapperOffset;
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  z-index: 1;

  @include mq($from: constrainedWrapper) {
    left: calc(50% - (#{$constrainedWidth} / 2));
  }
}

.section--with-wrapper-flourish-right::before {
  bottom: auto;
  top: 97%;
  left: auto;
  right: -15px;

  @include mq($from: constrainedWrapper) {
    right: -44px;
  }
}

.section--with-flourish-1::before {
  background-image: url("../media/images/flourish-1.svg");
  height: 70px;
  width: 117px;

  @include mq($from: medium) {
    height: 140px;
    width: 233px;
  }
}

.section--with-flourish-3::before {
  background-image: url("../media/images/flourish-3.svg");
  height: 64px;
  width: 167px;

  @include mq($from: medium) {
    height: 127px;
    width: 333px;
  }
}

.section--with-flourish-4::before {
  background-image: url("../media/images/flourish-4.svg");
  height: 73px;
  width: 170px;

  @include mq($from: medium) {
    height: 146px;
    width: 340px;
  }
}

.section--with-flourish-5::before {
  background-image: url("../media/images/flourish-5.svg");
  height: 105px;
  transform: translateY(-70%);
  width: 117px;

  @include mq($from: medium) {
    height: 180px;
    transform: translateY(-60%);
    width: 200px;
  }
}

.section--longFormText .wrapper > * + * {
  margin-top: 2rem;

  @include mq($from: medium) {
    margin-top: 3.5rem;
  }
}

.section--textPreamble {
  padding-bottom: 3rem;
  padding-top: 0;

  @include mq($from: medium) {
    .grid > * {
      grid-column: 1 / span 6;
    }
  }
}

.section--longFormText .section--textPreamble {
  margin: 0 auto;
  max-width: $contentConstraint;
  padding-bottom: 0;
  padding-top: 0;

  @include mq($from: medium) {
    .grid > * {
      grid-column: 1 / span 12;
    }
  }
}

.section--featureHero + .section--longFormText {
  padding-top: 2.875rem;

  @include mq($from: medium) {
    padding-top: 5rem;
  }
}

.section--featureHero + .section--textPreamble {
  padding-left: $wrapperOffset;
  padding-right: $wrapperOffset;
  padding-top: 5rem;
}

.section__scroll-offset-trigger {
  bottom: 0;
  right: 0;
  top: calc(var(--masthead-height) * -1);
  left: 0;
  position: absolute;
}

.section--loader {
  position: relative;
}

.section--loader::before {
  content: "";
  background-color: $lightest;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity $BASE_TEMPO $EASE_PRIMARY;
  width: 100%;
  z-index: 10;
}

.section--loader.is-loading::before {
  opacity: .91;
  pointer-events: all;
}

.section--poetSlider,
.section--themeSlider,
.section--recentColumns,
.section--featuredItemSlider {
  overflow-x: hidden;
}

.section--browsePoems + .section--featuredItemSlider {
  @include mq($from: medium) {
    padding-top: 7rem;
  }
}
